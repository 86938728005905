import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react"; 
import { RingLoader } from "react-spinners";
import { FaFilter, FaAngleDown, FaSearch } from 'react-icons/fa';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Spinner } from "reactstrap";
import { MoreVertical } from "react-feather";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { useHistory } from 'react-router-dom';
import { toastr } from "react-redux-toastr";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Pakkeliste_NO = () => {
  const history = useHistory();
  const [selectedPakkeListe, setSelectedPakkeListe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [skus, setSkus] = useState([]);

  const storedProject = JSON.parse(localStorage.getItem("erpProject"));
  if (!storedProject) {
    toastr.error('[Error]', 'You need to set the local project before accessing this module.');
    history.push("/expo-solutions/config");
  }

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePrint = () => {
    const printContents = document.getElementById("printTable").outerHTML;
    const printWindow = window.open('', '_blank');

    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="/css/modern.css" rel="stylesheet" type="text/css">');
    printWindow.document.write('<link href="/css/print.css" rel="stylesheet" type="text/css" media="print">');
    printWindow.document.write('<style>.card { page-break-inside: avoid; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');

    printWindow.document.close();
  };

  useEffect(() => {
    setLoading(true);
    let projectType = storedProject?.type;
    let url;
    if (projectType === "Boligmesse") {
      url = `https://api2.fairs.as/24so/soap/boligmesse-pakkeliste?projectId=${storedProject?.value}`;
    } else {
      url = `https://api2.fairs.as/24so/soap/external-pakkeliste?projectId=${storedProject?.value}`;
    }

    axios
      .get(url)
      .then((response) => {
        setSelectedPakkeListe(response.data);
        const fetchedSkus = response.data.results.map(item => item.SKU);
        setSkus(fetchedSkus);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Packing list</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Expo Solutions</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Packing list</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        {selectedPakkeListe && (
          <Col style={{padding:'unset'}}>
            <Card>
              <CardBody style={{padding:'1.75rem'}}>
                <div className="card-actions float-right">
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle tag="a">
                      <MoreVertical />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={handlePrint}>Print</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div style={{ position: 'relative', width: '200px' }}>
                  <input type="text" onChange={handleFilterChange} value={filter} placeholder="Search products" style={{ display: 'block', width: '100%', paddingLeft: '30px' }} />
                  <FaSearch style={{ position: 'absolute', left: '10px', top: '55%', transform: 'translateY(-50%)', color: 'gray' }} />
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </Container>

      {selectedPakkeListe && (
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5" className="mb-0">Results</CardTitle>
                </CardHeader>
                <CardBody>
                  <table id="printTable" className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Description</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPakkeListe.results
                        .filter((order) => filter.trim() === "" || order.SKU.toLowerCase().includes(filter.trim().toLowerCase()))
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item.SKU}</td>
                            <td>{item.DESC}</td>
                            <td>{item.Quantity}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      {loading && ( 
        <div className="loading-screen" style={{ marginTop: '125px' }}>
          <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
        </div>
      )}
    </>
  );
};

export default Pakkeliste_NO;

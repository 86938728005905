import React, { useEffect, useState, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { MinusCircle, PlusCircle } from 'react-feather';
import { Link } from "react-router-dom";
import { FaAngleDown, FaSearch } from "react-icons/fa"; // Importing icons

// Custom fetch functions
const fetchOrders = async () => {
  const response = await fetch('https://api.fairs.as/api/woo/v1/api/woo/v1/order-ids'); // Fixed endpoint
  const data = await response.json();
  return data;
}; 

const fetchBadgeStatus = async (orderId, status) => {
  const response = await fetch(`https://api.fairs.as/api/woo/v1/api/woo/v1/getStatus/${orderId}/${status}`);
  const data = await response.json();
  return data;
};

// Define columns
const columns = [
  {
    dataField: 'customerData.failed',
    text: 'Sync',
    sort: true,
    formatter: (cell, row) => (
      <span className={row.customerData.failed ? 'text-warning' : 'text-success'}>
        {row.customerData.failed ? 'Not Completed' : 'Completed'}
      </span>
    ),
    headerStyle: { width: '120px' },
    style: { whiteSpace: 'nowrap' }
  },
  {
    dataField: 'customerData.date',
    text: 'Date',
    sort: true,
    headerStyle: { width: '180px' },
  },
  {
    dataField: 'orderId',
    text: 'ID',
    sort: true,
    headerStyle: { width: '90px' },
    style: { whiteSpace: 'nowrap' }
  },
  {
    dataField: 'customerData.companyName',
    text: 'Company',
    sort: true,
    headerStyle: { width: '200px' },
  },
  {
    dataField: 'customerData.projectID',
    text: 'PID',
    sort: true,
    headerStyle: { width: '90px' },
    style: { whiteSpace: 'nowrap' }
  },
  {
    dataField: 'customerData.headerProjectInfo',
    text: 'Project',
    sort: true,
    headerStyle: { width: '400px' },
  },
  {
    dataField: 'customerData.standNumber',
    text: 'Standnr',
    sort: true,
    headerStyle: { width: '90px' },
  },
  {
    dataField: 'customerData.vatNumber_DK',
    text: 'VATnr',
    sort: true,
    headerStyle: { width: '150px' },
  },
  {
    dataField: 'customerData.paymentMethod',
    text: 'Paid with',
    sort: true,
    headerStyle: { width: '100px' },
  },
];

const handleResync = async (orderId, setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await fetch(`https://compassfairs.dk/wp-json/woo/v1/webhook/woocommerce/${orderId}`, {
      method: 'POST',
    });
    const result = await response.json();

    if (result.success === 'OK') {
      toastr.success('Resync Successful', `Order ${orderId} resynced successfully.`);
    } else {
      toastr.error('Resync Failed', `Order ${orderId} resync failed.`);
    }
  } catch (error) {
    toastr.error('Resync Failed', `Order ${orderId} resync failed due to an error.`);
  } finally {
    setIsLoading(false);
  }
};

// Status description mapping
const statusDescriptions = {
  'success': 'The order has been successfully synced with economic.',
  'webshop': 'The order data sent from the webshop.',
  'update': 'The order status has been resynced.',
  'error': 'There was an error processing the order.',
  'customer': 'The customer details sent to economic.',
};

// Convert bracketed status to lowercase readable status
const convertStatus = status => {
  return status.replace('[', '').replace(']', '').toLowerCase();
};

// Expandable row renderer
const ExpandableRow = ({ orderStatus, orderId, onStatusClick }) => {
  const [isLoading, setIsLoading] = useState(false); // New state to handle loading

  return (
    <div style={{ padding: '10px', backgroundColor: '#f8f9fa' }}>
      {orderStatus.map(status => {
        const readableStatus = convertStatus(status);
        return (
          <div
            key={status}
            style={{
              marginBottom: '10px',
              cursor: 'pointer',
              padding: '10px',
              borderRadius: '5px',
              backgroundColor: '#ffffff',
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            }}
            onClick={() => onStatusClick(readableStatus, orderId)}
          >
            <div style={{ fontWeight: 'bold' }}>{readableStatus}</div>
            <div>{statusDescriptions[readableStatus] || 'No explanation available.'}</div>
          </div>
        );
      })}

      <Button
        color="primary"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          handleResync(orderId, setIsLoading);
        }}
        disabled={isLoading} // Disable button while loading
      >
        {isLoading ? <Spinner size="sm" /> : 'Resync'} {/* Show spinner when loading */}
      </Button>
    </div>
  );
};

const ExpandableRowsTable = () => {
  const [orders, setOrders] = useState([]);
  const [modal, setModal] = useState(false);
  const [badgeData, setBadgeData] = useState({});
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(''); // State for the status filter
  const [orderStatus, setorderStatus] = useState(false); // Dropdown state
  const dropdownRef = useRef(null);

  useEffect(() => {
    const loadOrders = async () => {
      const result = await fetchOrders();
      setOrders(result);
    };
    loadOrders();
  }, []);

  const handleStatusClick = async (status, orderId) => {
    console.log('Handling status click for orderId:', orderId, 'status:', status);
    setCurrentOrderId(orderId); // Update current orderId
    const data = await fetchBadgeStatus(orderId, status);
    setBadgeData(data);
    setModal(true);
  };

  const toggleModal = () => setModal(!modal);

  const handleSearchChange2 = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilterChange = (status) => {
    if (selectedStatusFilter === status) {
      setSelectedStatusFilter(''); 
    } else {
      setSelectedStatusFilter(status);
    }
    setorderStatus(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setorderStatus(false); // Close dropdown when clicking outside
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOrders = orders.filter((order) => {
    const pidMatch = order.customerData.projectID.toLowerCase().includes(searchTerm.toLowerCase());
    const projectMatch = order.customerData.headerProjectInfo.toLowerCase().includes(searchTerm.toLowerCase());
    const orderIdMatch = order.orderId.toString().includes(searchTerm);
    const companyMatch = order.customerData.companyName.toLowerCase().includes(searchTerm.toLowerCase());
    const standNr = order.customerData.standNumber.toLowerCase().includes(searchTerm.toLowerCase());
    const vatnr = order.customerData.vatNumber_DK.toLowerCase().includes(searchTerm.toLowerCase());
    const paymentMethod = order.customerData.paymentMethod.toLowerCase().includes(searchTerm.toLowerCase());
    const statusMatch = selectedStatusFilter
      ? (selectedStatusFilter === 'Completed' && !order.customerData.failed) ||
        (selectedStatusFilter === 'Not Completed' && order.customerData.failed)
      : true;

    return (pidMatch || projectMatch || orderIdMatch || companyMatch || standNr || vatnr || paymentMethod ) && statusMatch;
  });

  const expandRow = {
    renderer: row => (
      <ExpandableRow
        orderStatus={row.orderStatus}
        orderId={row.orderId}
        onStatusClick={handleStatusClick}
      />
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Economic logs overview</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Denmark Project</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Economic Logs</BreadcrumbItem>
        </Breadcrumb>
      </Header>
  <Row>
    <Col>
  <Card>
  <CardBody style={{ padding: '1.75rem' }}>
    <Row>
      <Col md="6" className="d-flex align-items-center">
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <span
            className="badge2"
            onClick={() => setorderStatus(!orderStatus)}
            style={{
              backgroundColor: selectedStatusFilter ? 'lightgrey' : '',
              color: '#f9fafc',
              borderRadius: '20px',
              padding: '5px 15px',
              cursor: 'pointer'
            }}
          >
            Status <FaAngleDown />
          </span>
          {orderStatus && (
            <div
              ref={dropdownRef}
              className="dropdown-menu2"
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                zIndex: '1000',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '5px',
                fontSize: 'smaller'
                
              }}
            >
              {['Completed', 'Not Completed'].map((status) => (
                <div
                  key={status}
                  onClick={() => handleStatusFilterChange(status)}
                  style={{
                    width: "100%",
                    display: 'inline-block',
                    cursor: 'pointer'
                  }}
                >
                  {status} {selectedStatusFilter === status && '✓'}
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>
      <Col md="6" className="d-flex justify-content-end">
        <div style={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
          <input
            type="text"
            onChange={handleSearchChange2}
            value={searchTerm}
            placeholder="Search orders"
            style={{ display: 'block', width: '100%', paddingLeft: '30px' }}
          />
          <FaSearch
            style={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'gray'
            }}
          />
        </div>
      </Col>
    </Row>
  </CardBody>
</Card>
</Col>  
</Row>   

      
      
  
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Economic Sync Logs</CardTitle>
              <h6 className="card-subtitle text-muted">
                Expandable rows with nested status details
              </h6>
            </CardHeader>
            <CardBody>
              <ToolkitProvider
                keyField="orderId"
                data={filteredOrders}
                columns={columns}
                bootstrap4
              >
                {props => (
                  <div>
                    <BootstrapTable
                      {...props.baseProps}
                      expandRow={expandRow}
                      bordered={false}
                      pagination={paginationFactory({
                        sizePerPage: 10, // Ensures 5 rows per page
                        sizePerPageList: [10, 20, 40], // Options for rows per page
                        showTotal: true, // Shows the total number of records
                      })}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
  
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Badge Details</ModalHeader>
        <ModalBody>
          <pre>{JSON.stringify(badgeData, null, 2)}</pre>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default ExpandableRowsTable;

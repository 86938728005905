import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash,faSearch } from "@fortawesome/free-solid-svg-icons";
import useDynamicToastr from "../../../notification/helper.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Search } from "react-feather";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  InputGroup,
  InputGroupText,
  Input 
} from "reactstrap";

import ImportUsersModal from './modalFileUpload.js'; 

import AddUserModal from './addUserModal.js';
import EditUserModal from './EditUserModal.js';
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

import { MoreVertical, RefreshCw } from "react-feather";

const ClientsList = () => {
    const showToastr = useDynamicToastr();
    const [users, setUsers] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(null);
    console.log(users);
    const availableRoles = ['Administrator', 'Fairs', 'Expo Solutions', 'Messe', 'Media', 'Assembler'];
    const [loading, setLoading] = useState(true);
    const openModal = () => {
        setIsModalOpen(true);
      };

      const [filter, setFilter] = useState({ keyword: '', name : '', email: ''});

      const filterUsersByRolesAndSearch = (users, selectedRoles, filter) => {
        // Apply role-based filtering
        const roleFilteredUsers = selectedRoles.length === 0 
          ? users 
          : users.filter(user => selectedRoles.every(role => user.roles.includes(role)));
        
        // Apply search-based filtering
        return roleFilteredUsers.filter(
          user => 
            user.name.toLowerCase().includes(filter.email.toLowerCase()) &&
            user.email.toLowerCase().includes(filter.name.toLowerCase())
        );
      };
      // Function to close the modal
      const closeModal = () => {
        setIsModalOpen(false);
      };

      const [isImportModalOpen, setIsImportModalOpen] = useState(false);

      const openModalCSV = () => {
        setIsImportModalOpen(true);
      };
    
      const closeImportModal = () => {
        setIsImportModalOpen(false);
      };

      const closeModal1 = () => {
        setIsModalOpen1(false);
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUserData({
          ...newUserData,
          [name]: value,
        });
      };

      const openEditModal = (userId) => {
        setCurrentUserId(userId); // Set the current user ID
        setIsModalOpen1(true);       // Open the modal
      };

      const [newUserData1, setNewUserData1] = useState({ name: '', email: '', mobile: '', roles: [], country: '' });

      const addUser = async (selectedRoles) => {
        try {
          let send = true;
          if (!newUserData.email || !newUserData.mobile || !selectedRoles) {
            showToastr('warning', 'Required fields are empty', '[FAILED]');
            send = false;
          }
      
          console.log(newUserData);
      
          // Prepare data to send
          const userData = {
            email: newUserData.email,
            firstName: newUserData.firstName,
            lastName: newUserData.lastName,
            mobile: newUserData.mobile,
            verified: newUserData.verified,
            roles: selectedRoles,
            country: newUserData.country,
          };
      
          if (send) {
            const response = await fetch('https://api2.fairs.as/auth/user/add', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(userData),
            });
      
            if (!response.ok) {
              const errorMessage = await response.text();
              throw new Error(errorMessage);
            } else {
              showToastr('success', 'User successfully added', '[OK]');
              // Close the modal
              closeModal();
            }
          }
        } catch (error) {
          const errorMessage = error.message || 'Unknown error occurred';
          showToastr('error', 'Error adding user', errorMessage);
        }
      };
      

      const handleDelete = (email) => {
        const confirmed = window.confirm(`Are you sure you want to delete this user? ${email}`);
        
        if (confirmed && window.admin) {
          // Send a DELETE request to the backend API
          fetch(`https://api2.fairs.as/auth/user/${encodeURIComponent(email)}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => {
              if (response.ok) {
                // User deleted successfully
                showToastr('info', 'User deleted', email);
              } else if (response.status === 404) {
                // User not found
                showToastr('warning', 'User not found', email);
              } else {
                // Handle other errors as needed
                showToastr('error', 'Error deleting user', email);
              }
            })
            .catch((error) => {
              console.error('Error deleting user:', error);
              showToastr('error', 'Error deleting user', email);
            });
        } else {
          showToastr('warning', 'User not deleted', email);
        }
      };
      const [selectedRoles, setSelectedRoles] = useState([]);
      const filterUsersByRoles = (users, selectedRoles) => {
        if (selectedRoles.length === 0) return users; // No filtering if no roles are selected
        return users.filter((user) => 
          selectedRoles.every(role => user.roles.includes(role))
        );
      };

      const [visibleSearch, setVisibleSearch] = useState(null);

      // Function to toggle the search box visibility
      const toggleSearch = (field) => {
        if (field === "roles") {
          setVisibleRolesSelect(!visibleRolesSelect);
          setVisibleSearch(null);
        } else {
          setVisibleSearch(visibleSearch === field ? null : field);
          setVisibleRolesSelect(false); 
        }
      };

      const preventHide = (event) => {
        event.stopPropagation(); // Prevent event from propagating
      };

      const [visibleRolesSelect, setVisibleRolesSelect] = useState(false);

      const toggleRolesSelect = () => {
        setVisibleRolesSelect(!visibleRolesSelect);
      };
      

      const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal
      const [isModalOpen1, setIsModalOpen1] = useState(false); // State to control the modal
      const [newUserData, setNewUserData] = useState({
        name: '',
        email: '',
        number: '',
      });
      const refreshData = () => {
        setLoading(true);
        fetch('https://api2.fairs.as/auth/users')
          .then((response) => response.json())
          .then((data) => {
            setUsers(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      };
    useEffect(() => {
        refreshData()
    }, []);
  
    const columns = [
      {
        dataField: "picture",
        text: "",
        formatter: (cell) => (
          <img
            src={cell || "https://placehold.co/100x100?text=C+F"}
            width="32"
            height="32"
            className="rounded-circle"
            alt="Avatar"
          />
        ),
        headerStyle: { width: "60px" },
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "number",
        text: "Number",
        sort: true,
      },
      {
        dataField: "country",
        text: "Country",
        sort: true,
      },
      {
        dataField: 'roles',
        text: 'Roles',
        formatter: (cell) => (
          <>
            {cell?.map((role, index) => (
              <span key={index} className="badge badge-secondary mr-1">
                {role}
              </span>
            ))}
          </>
        ),
        sort: true,
      },
      {
        dataField: "lastSignedIn",
        text: "Last Signed In",
        sort: true,
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: (cell, row) => (
          <>
            <FontAwesomeIcon
              icon={faPen}
              fixedWidth
              className="align-middle mr-1"
              style={{ cursor: "pointer" }}
              onClick={() => openEditModal(row.uid)}
            />
            <FontAwesomeIcon
              icon={faTrash}
              fixedWidth
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.uid)}
            />
          </>
        ),
        headerStyle: { width: "150px" },
      },
    ];
    
  
    return (
      <>
    <Container fluid className="full-width px-0">
      <Row>
        {/* Left column: Filter settings */}
        <Col sm={6}>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 pt-2">
                Select Roles
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Select
                isMulti
                options={availableRoles.map((role) => ({
                  value: role,
                  label: role,
                }))}
                onChange={(selected) => {
                  const roleValues = selected
                    ? selected.map((option) => option.value)
                    : [];
                  setSelectedRoles(roleValues);
                    }}
                    placeholder="Filter by roles..."
                    onClick={preventHide}
                  />
            </CardBody>
          </Card>
        </Col>

        {/* Right column: Search */}
        <Col sm={6}>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 pt-2">
                Search
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex align-items-center justify-content-between">
                <Input
                  type="text"
                  placeholder="Search by keyword"
                  className="w-100"
                  onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
      <Card>
        <CardHeader>
          <div className="card-actions float-right">
            <span className="cursor-pointer mr-1" onClick={refreshData}>
              <RefreshCw />
            </span>{" "}
            <UncontrolledDropdown className="d-inline-block">
              <DropdownToggle tag="a">
                <MoreVertical />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={openModal}>Add New User</DropdownItem>
                <DropdownItem onClick={openModalCSV}>Import Users (CSV)</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <CardTitle tag="h5">Users</CardTitle>
          
        </CardHeader>
        <CardBody>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <BootstrapTable
              keyField="uid"
              data={filterUsersByRolesAndSearch(users, selectedRoles, filter)}
              columns={columns}
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                sizePerPage: 25,
                sizePerPageList: [10, 20, 50],
                showTotal: true,
                paginationTotalRenderer: ({ from, to, total }) =>
                  ""
              })}
            />
          )}
        </CardBody>
        <AddUserModal
          isOpen={isModalOpen}
          toggleModal={closeModal}
          newUserData={newUserData}
          handleInputChange={handleInputChange}
          addUser={addUser}
        />
        <EditUserModal
          isOpen={isModalOpen1}
          toggleModal={closeModal1}
          newUserData={newUserData1}
          setNewUserData={setNewUserData1}
          handleInputChange={handleInputChange}
          addUser={addUser}
          uid={currentUserId}
        />

        <ImportUsersModal
          isOpen={isImportModalOpen}
          toggleModal={closeImportModal}
          uploadUrl="https://api2.fairs.as/auth/import-users"
        />
      </Card>
      </>
    );
  };

const Clients = () => (
  <Container fluid>
    <Header>
      <HeaderTitle>Brukere</HeaderTitle>

      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/dashboard">Dashboard</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Brukere</BreadcrumbItem>
      </Breadcrumb>
    </Header>

    <Row>
      <Col xs="12" className="col-xxl-12">
        <ClientsList />
      </Col>
    </Row>
  </Container>
);

export default Clients;

import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { useSessionRedirect } from "./pages/auth/checkSession";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import { UserDataProvider } from "./context/UserDataContext";
import { ProjectProvider } from "./context/ProjectContext";
import { permissions, hasPermission, isAdminRole } from "./access/accessControl";
import "./inactivity/main";
const App = () => {
  useEffect(() => {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
       //HARD CODED ATM...
       const projectId = 604297;
       const projectName = 'Oslo/Hellerudsletta';
       localStorage.setItem('projectId', projectId.toString());
       localStorage.setItem('projectName', projectName);
  }, []);


  
  const userData = JSON.parse(localStorage.getItem('userData')) || 'INGEN VERDI';
  const projectInfo = JSON.parse(localStorage.getItem('projectId'));
  if(userData != 'INGEN VERDI') {
    const userRoles = userData ? userData.userRoles : [];
    const requiredPermission = permissions['Access Brukeradministrasjon'];
    const isAdmin = isAdminRole(userRoles);
    const returnRoles = hasPermission(userRoles, requiredPermission, true);
    window.admin = isAdmin;
    window.Roles = returnRoles || [];
  }

  return (
    <Provider store={store}>
      <ProjectProvider value={projectInfo}>
        <UserDataProvider userData={userData} value={projectInfo}>
          <Routes projectId={projectInfo} />
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </UserDataProvider>
      </ProjectProvider>
    </Provider>
  );
};

export default App;

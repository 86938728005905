import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const ImportUsersModal = ({ isOpen, toggleModal, uploadUrl }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      setSelectedFile(file);
      setError(null);
    } else {
      setError('Please select a .csv file.');
      setSelectedFile(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Please select a .csv file.');
      return;
    }

    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        setError(`Upload failed: ${errorMessage}`);
      } else {
        alert('File uploaded successfully!');
        toggleModal(); // Close the modal
      }
    } catch (err) {
      setError('An error occurred during the upload.');
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <Modal show={isOpen} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Import Users (CSV)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Upload a CSV File:</Form.Label>
            <Form.Control
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              disabled={loading} // Disable file input when loading
            />
            {error && <div className="text-danger">{error}</div>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal} disabled={loading}>Close</Button>
        <Button onClick={handleUpload} disabled={!selectedFile || loading}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Upload'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportUsersModal;

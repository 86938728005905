import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'; 
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";  // Import FontAwesome icons
import { toastr } from "react-redux-toastr";

const SalesOpp = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [localDeviceProject, setLocalDeviceProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [salesOpps, setSalesOpps] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [personArray, setPersonArray] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);  // Modal for viewing details
  const [currentRow, setCurrentRow] = useState(null);
  const [newDescription, setNewDescription] = useState("");
  const [ticketInfo, setTicketInfo] = useState([]);
  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleViewModal = () => setViewModalOpen(!viewModalOpen);  // Toggle view modal

  const handleEdit = (row) => {
    setCurrentRow(row);
    setNewDescription(row.Description);
    toggleModal();
  };

  const handleView = (row) => {
    setCurrentRow(row);
    toggleViewModal();
  };

  const handleDelete = (row) => {
    alert(`Are you sure you want to delete ID: ${row.Id}?`);
  };

  const handleSave = async () => {
    const salesOpportunity = {
      salesOpp: {
        Title: currentRow.Title,
        Id: currentRow.Id,
        Description: newDescription,
        ProjectId: currentRow.ProjectId,
        CustomerId: currentRow.CustomerId,
        DateChanged: new Date().toISOString(),
      }, 
    };

    try {
      const response = await axios.put(`https://api2.fairs.as/24so/soap/updateSalesOpp?id=${currentRow.Id}`, salesOpportunity);
      if (response.data.status === 'successfully updated') {
        toastr.success("[OK]", `updated ${currentRow.Description} to ${newDescription}`);

        setSalesOpps(prevSalesOpps =>
          prevSalesOpps.map(salesOpp =>
            salesOpp.Id === currentRow.Id
              ? { ...salesOpp, Description: newDescription }
              : salesOpp
          )
        );
        toggleModal();
      } else {
        alert("Update failed");
      }
    } catch (error) {
      console.error("Error updating stand number:", error);
      alert("Update failed");
    }
  };

  useEffect(() => {
    const loadOptions = async (inputValue = "") => {
      setIsLoading(true);
      try {
        const apiUrl = "https://api2.fairs.as/24so/soap/projects";
        const response = await axios.get(apiUrl);

        const projectOptions = response.data.Project
          .filter(
            (item) =>
              item.Id.toString().includes(inputValue) ||
              item.Name.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((item) => ({
            value: item.Id,
            label: item.Name,
            type: item.TypeName
          }));

        setProjectOptions(projectOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadOptions();
  }, []);

  const handleLocalDeviceProjectChange = async (selectedOption) => {
    setLocalDeviceProject(selectedOption);
    if (selectedOption) {
      try {
        const apiUrl = `https://api2.fairs.as/24so/soap/salesOpp?projectId=${selectedOption.value}`;
        const response = await axios.get(apiUrl);
        setSalesOpps(response.data.enrichedSalesOpps);
  
        const industriesArray = response.data.industries.KeyValuePair;
        const industriesObject = industriesArray.reduce((acc, pair) => {
          acc[pair.Key] = pair.Value;
          return acc;
        }, {});
  
        const personsArray = response?.data?.getPersons || null;
        setPersonArray(personsArray);
        setIndustries(industriesObject);

        const ticketInfoUrl = `https://compassfairs.no/wp-json/fairsas/getTicketInfo?pid=${selectedOption.value}`;
        const ticketResponse = await axios.get(ticketInfoUrl);
  
        // Map email as the key and the rest of the data as value
        const ticketInfoObject = ticketResponse?.data?.response.reduce((acc, person) => {
          acc[person?.Email] = {
            Name: person?.Name,
            ticketUrl: person?.ticketUrl,
          };
          return acc;
        }, {});

        console.log(ticketInfoObject);
        // Store the ticket info in the state
        setTicketInfo(ticketInfoObject);
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setSalesOpps([]);
    }
  };
  

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: 'CustomerId',
      text: 'KundeID',
      sort:true,
    },
    {
      dataField: 'Description',
      text: 'Standnr',
      sort: true, 
    },
    {
      dataField: 'company.Name',
      text: 'Utstillernavn',
      sort:true,
    },
    {
      dataField: 'CustomerContactId',
      text: 'Kontaktperson',
      sort:true,
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
        return `${relevantRelation?.FirstName || 'N/A'} ${relevantRelation?.LastName || 'N/A'}`;
      },
    },
    {
      dataField: 'CustomerContactId',
      text: 'Kontaktperson e-post',
      sort:true,
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
        return relevantRelation?.EmailAddresses?.Work?.Value || 'N/A';
      },
    },
    {
      dataField: 'CustomerContactId',
      text: 'Kontaktperson tlf',
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
        return relevantRelation?.PhoneNumbers?.Mobile?.Value || '';
      },
    },
    {
      dataField: 'Actions',
      text: 'Actions',
      formatter: (cell, row) => (
        <>
          <FaEye onClick={() => handleView(row)} style={{ cursor: "pointer", marginRight: 10 }} />
          <FaEdit onClick={() => handleEdit(row)} style={{ cursor: "pointer", marginRight: 10 }} />
          <FaTrash onClick={() => handleDelete(row)} style={{ cursor: "pointer" }} />
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>SalesOpp Page</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Boligmesse</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="#">SalesOpp</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>SalesOpp Page</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 pt-2">
                Select Project
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Select
                options={projectOptions}
                isClearable
                onChange={handleLocalDeviceProjectChange}
                value={localDeviceProject}
                isSearchable
                placeholder="Select or search for projects..."
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {salesOpps.length > 0 && (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                  Sales Opportunities
                  <span style={{float:'right'}}>Total stands: {salesOpps.length}</span>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  keyField="Id"
                  data={salesOpps}
                  columns={columns}
                  search
                >
                  {
                    props => (
                      <div>
                        <SearchBar {...props.searchProps} placeholder="Search in table..." />
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          hover
                          condensed
                          striped
                          noDataIndication="No data available"
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

{/* View Modal */}
<Modal isOpen={viewModalOpen} toggle={toggleViewModal}>

<ModalBody style={{ border: '1px solid #ccc' }}> {/* Add border to the entire modal body */}
    {currentRow && (
        <div>
            {/* Header Row */}
            <div className="row" style={{ border: '1px solid #ccc', borderBottom: 'none', marginBottom: '-1px' }}>
                <div className="col-6" style={{ borderRight: '1px solid #ccc', padding: '10px', boxSizing: 'border-box' }}>
                    <p><strong>Utstillernavn:</strong> {currentRow.company.NickName || 'N/A'}</p>
                </div>
                <div className="col-6" style={{ padding: '10px', textAlign: 'right', boxSizing: 'border-box' }}>
                    <p><strong>Standnr:</strong> {currentRow.Description || 'N/A'}</p>
                </div>
            </div>

            {/* Body Section */}
            <div style={{ padding: '10px 0', border: '1px solid #ccc', borderTop: 'none' }}>
                <div style={{ margin: '15px', }}> {/* Container for body text with left margin */}
                    <p><strong>KundeID:</strong> {currentRow.CustomerId || 'N/A'}</p>
                    <p><strong>Org. nr.: </strong>{currentRow.company.OrganizationNumber || 'N/A'}</p>
                    <p><strong>Firmanavn: </strong>{currentRow.company.Name || 'N/A'}</p>
                    <p><strong>Bransje: </strong>{industries[currentRow.company.IndustryId] || 'N/A'}</p>
                    <p><strong>Produkt: </strong>{currentRow.company.ExternalId || 'N/A'}</p>
                    <br/>
                    <p><strong>Kontaktperson: </strong>{personArray[currentRow.Owner] || 'N/A'}</p>
                    <p><strong>Kontaktperson e-post: </strong>{currentRow.company?.Relations?.Relation?.find(
                        (relation) => relation.ContactId === currentRow.CustomerContactId
                    )?.EmailAddresses?.Work?.Value || 'N/A'}</p>
                    <p><strong>Kontaktperson mobil: </strong>{currentRow.company?.Relations?.Relation?.find(
                        (relation) => relation.ContactId === currentRow.CustomerContactId
                    )?.PhoneNumbers?.Mobile?.Value || 'N/A'}</p>
                    <p><strong>Prosjektnavn (Prosjektnummer): </strong>{currentRow.Title} ({currentRow.ProjectId})</p>
                    <p><strong>ChecklistID: </strong>{/* Logic needed */}</p>
                    <hr/>
                    <p><strong>Billettlink: </strong>{ticketInfo[currentRow.company?.Relations?.Relation?.find(
                        (relation) => relation.ContactId === currentRow.CustomerContactId
                    )?.EmailAddresses?.Work?.Value]?.ticketUrl || 'N/A'}</p>
                </div>
            </div>
        </div>
    )}
</ModalBody>




    <ModalFooter>
        <Button color="secondary" onClick={toggleViewModal}>Close</Button>
    </ModalFooter>
</Modal>


      {/* Edit Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Stand Number</ModalHeader>
        <ModalBody>
          {currentRow && (
            <div>
              <p><strong>Customer ID:</strong> {currentRow.CustomerId}</p>
              <p><strong>Current Description:</strong></p>
              <Input
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default SalesOpp;

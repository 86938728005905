import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../../src/components/Header";
import HeaderTitle from "../../../src/components/HeaderTitle";
import { fetchStoredProjects, fetchAssemblers } from "./api/assembler_api";

const AssemblerSettings = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [assemblerAppProject, setAssemblerAppProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [assemblers, setAssemblers] = useState([]);
  const [selectedAssemblers, setSelectedAssemblers] = useState([]);

  useEffect(() => {
    fetchStoredProjects(setProjectOptions, setIsLoading);
    fetchAssemblers(setAssemblers);
  }, []);

  const handleProjectChange = async (selectedProject) => {
    setAssemblerAppProject(selectedProject);
    setSelectedAssemblers([]); // Clear selected assemblers when project changes

    try {
      const response = await axios.get(`https://api2.fairs.as/ta-orders/getAssignedassemblers/${selectedProject.value}`);
      const assignedAssemblers = response.data;

      // Map assigned assemblers to match current assemblers state
      const updatedAssemblers = assemblers.map(assembler => {
        const found = assignedAssemblers.find(item => item.assemblerId === assembler.uid);
        if (found) {
          return {
            ...assembler,
            isSelected: found.isSelected,
            categories: found.categories.map(category => ({ value: category, label: category })),
          };
        }
        return assembler;
      });

      setAssemblers(updatedAssemblers);
    } catch (error) {
      console.error('Error fetching assigned assemblers:', error);
      // Handle error fetching assigned assemblers
      setAssemblers([]); // Reset assemblers state to empty array
      setSelectedAssemblers([]); // Clear selected assemblers state
      toastr.warning("[Warning]", `Assemblers needs to be assigned to this project`);
      fetchAssemblers(setAssemblers);
    }
  };

  const handleCategorySelect = (selectedOption, assembler) => {
    const updatedAssemblers = assemblers.map((item) =>
      item.uid === assembler.uid ? { ...item, categories: selectedOption } : item
    );
    setAssemblers(updatedAssemblers);
  };

  const handleCheckboxChange = (event, assembler) => {
    const isChecked = event.target.checked;
    const updatedAssemblers = assemblers.map((item) =>
      item.uid === assembler.uid ? { ...item, isSelected: isChecked } : item
    );
    setAssemblers(updatedAssemblers);

    if (isChecked) {
      setSelectedAssemblers([...selectedAssemblers, assembler]);
    } else {
      setSelectedAssemblers(selectedAssemblers.filter((item) => item.uid !== assembler.uid));
    }
  };

  const saveAssignedAssemblers = async () => {
    let countAssigned = 0;
    try {
      for (const assembler of assemblers) {
        
          
          const categoriesToSave = assembler.categories ? assembler.categories.map((category) => category.value) : [];
          if (typeof assembler.isSelected === 'boolean' && categoriesToSave.length > 0) {
            countAssigned++;
            await axios.post("https://api2.fairs.as/ta-orders/assign", {
                projectId: assemblerAppProject.value,
                assemblerId: assembler.uid,
                categories: categoriesToSave,
                isSelected: assembler.isSelected,
            });
        }
      }
      toastr.success("[SUCCESS]", `Successfully assigned`);
      setSelectedAssemblers([]);
    } catch (error) {
      toastr.error("[ERROR]", `Assemblers not assigned, something went wrong!`);
      console.error("Error saving assigned assemblers:", error);
    }
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Assign Assemblers</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Assembler App</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Assemblers</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 pt-2">
                Select Project to Assign Assemblers
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Select
                options={projectOptions}
                isClearable
                onChange={handleProjectChange}
                value={assemblerAppProject}
                isSearchable
                placeholder="Select or search for projects..."
                isLoading={isLoading}
              />

              {assemblerAppProject && (
                <div>
                  <div className="my-3"></div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>Name</th>
                          <th>Category</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assemblers.map((assembler) => (
                          <tr key={assembler.uid}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, assembler)}
                                checked={assembler.isSelected}
                              />
                            </td>
                            <td>{assembler.name}</td>
                            <td>
                              <Select
                                options={[
                                  { value: "Flooring", label: "Flooring" },
                                  { value: "Storage", label: "Storage" },
                                  { value: "Furniture", label: "Furniture" },
                                  { value: "Stand profiling, walls & front boards", label: "Stand profiling, walls & front boards" },
                                  { value: "Starter packs", label: "Starter packs" },
                                  { value: "Power and Light", label: "Power and Light" },
                                  { value: "Accessories & small electrical", label: "Accessories & small electrical" },
                                ]}
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable
                                placeholder="Select category"
                                onChange={(selectedOption) =>
                                  handleCategorySelect(selectedOption, assembler)
                                }
                                value={assembler.categories}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="my-3">
                    <button className="btn btn-primary" onClick={saveAssignedAssemblers}>
                      Save Assigned Assemblers
                    </button>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AssemblerSettings;

import roles from './roles';

export const permissions = {
    'Access Brukeradministrasjon': 'Access Brukeradministrasjon',
    'Access Ordrerapport': 'Access Ordrerapport',
    'Access Instillinger': 'Access Instillinger',
    'Access Utstillerrapport': 'Access Utstillerrapport',
    'Access Salgsrapport': 'Access Salgsrapport',
    'Access Avviksrapport': 'Access Avviksrapport',
    'Access Kunderapport': 'Access Kunderapport',
    'Access Billettrapport': 'Access Billettrapport',
    'Access Rigge og pakkeliste': 'Access Rigge og pakkeliste',
    'Access SMS': 'Access SMS',
    'Access Besøkende': 'Access Besøkende',
    'Access Utstillere': 'Access Utstillere',
    'Access Linkutsendelse': 'Access Linkutsendelse',
    'Access Dagsrapport messe': 'Access Dagsrapport messe',
    'Access Monteringsapp': 'Access Monteringsapp',
  };

  export function hasPermission(userRoles, requiredPermission, displayRole = false) {
    // Check if userRoles exist
    
    if (!userRoles || userRoles.length === 0) {
      console.log('No user roles or permissions granted.');
      return false;
    }
  
    const userRolesWithPermission = [];
  
    for (const role of userRoles) {
      const rolePermissions = roles[role]?.permissions || [];
      if (rolePermissions.includes(requiredPermission) || rolePermissions.includes('*')) {
        userRolesWithPermission.push(role);
        if(displayRole) {
            return userRolesWithPermission
        } else {
            return true;
        }
        
      } else {
        userRolesWithPermission.push(role);
      }
    }
  
    if (userRolesWithPermission.length > 0) {
        return userRolesWithPermission
      console.log('User has the following roles:', userRolesWithPermission.join(', '));
    } else {
      console.log('User does not have access to', requiredPermission);
    }
    return false;
  }


  export function isAdminRole(userRoles) {
    if (!userRoles || userRoles.length === 0) {
      console.log('No user roles or permissions granted.');
      return false;
    }
  
    for (const role of userRoles) {
      if (role === 'Administrator') { // Check for 'Administrator' role (case-sensitive)
        return true;
      }
    }
  
    console.log('User is not an admin.');
    return false;
  }
  
  


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container, 
  Row,
  Button,
} from "reactstrap";
import { RingLoader } from "react-spinners";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

const override = css`
  display: block;
  margin: 0 auto;
`;

const ImportExhibitors = () => {
  // State declarations
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  // Fetch projects
  useEffect(() => {
    setIsLoadingProjects(true);
    axios
      .get("https://api2.fairs.as/24so/soap/projects")
      .then((response) => {
        const projects = response.data.Project.map((project) => ({
          value: project.Id,
          label: project.Name,
        }));
        setProjectOptions(projects);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      })
      .finally(() => {
        setIsLoadingProjects(false);
      });
  }, []);

  // Handle project selection
  const handleProjectSelect = (selectedOption) => {
    setSelectedProject(selectedOption);
  };

  // Handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    validateAndSetFile(droppedFile);
  };

  // Handle file select via input
  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    validateAndSetFile(selectedFile);
  };

  // Validate CSV file
  const validateAndSetFile = (selectedFile) => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split("\n");
        const headers = lines[0].split(",").map(header => header.trim()); // Trim each header to remove extra spaces or control characters
        console.log(headers);
  
        if (headers.includes("CompanyName") && headers.includes("Stand")) {
          setFile(selectedFile);
        } else {
          toastr.error("Invalid CSV", "CSV must contain CompanyName and Stand columns.");
          setFile(null);
        }
      };
      reader.readAsText(selectedFile);
    } else {
      toastr.error("Invalid File", "Please upload a CSV file.");
      setFile(null);
    }
  };
  

  // Handle form submit (file upload)
  const handleSubmit = () => {
    if (!selectedProject?.value) {
      toastr.error("[ERROR]", "No project selected.");
      return;
    }

    if (!file) {
      toastr.error("[ERROR]", "No file selected.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`https://api2.fairs.as/ta-orders/${selectedProject.value}/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toastr.success("Success", response.data.message || "File uploaded successfully.");
        setFile(null); // Clear the file after successful upload
      })
      .catch((error) => {
        toastr.error("Error", "Failed to upload file.");
        console.error("Error uploading file:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Orders report</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Reports</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Orders</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Exhibitor list uploader <br/>
                  <small>CSV Format required: CompanyName, Stand</small>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {/* Project Select */}
                <div className="my-3">
                  <p>Project</p>
                  <Select
                    isClearable
                    options={projectOptions}
                    value={selectedProject}
                    onChange={handleProjectSelect}
                    isLoading={isLoadingProjects}
                    placeholder="Select Project"
                  />
                </div>

                {/* Drag and Drop Area */}
                <div
                  onDrop={handleFileDrop}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    border: "2px dashed #007bff",
                    borderRadius: "5px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {file ? (
                    <p>File selected: {file.name}</p>
                  ) : (
                    <p>Drag & Drop your CSV file here or click to select</p>
                  )}
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileSelect}
                  />
                </div>

                {/* Button to execute request */}
                <Button color="primary" onClick={handleSubmit} className="mt-3">
                  Upload
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {loading && (
        <div className="loading-screen">
          <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
        </div>
      )}
    </>
  );
};

export default ImportExhibitors;

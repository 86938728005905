import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import returnUserData from './userInfo';
import classNames from "classnames";
import { ProjectContext } from "../context/ProjectContext";
import { Badge, Collapse } from "reactstrap";

import IosPulse from "react-ionicons/lib/IosPulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import routes from "../routes/index";

import avatar from "../assets/img/avatars/avatar.jpg";

const initOpenRoutes = (location) => {
  const pathName = location.pathname;

  let _routes = {};
  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {[index]: isActive || isOpen || isHome})
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({ name, badgeColor, badgeText, icon, isOpen, children, onClick, location, to, Permissions }) => {
    
    const [hasPermission, setHasPermission] = useState(false);
    const [pageReloaded, setPageReloaded] = useState(false);

    useEffect(() => {
      if (Permissions) {
        if (window.Roles && window.Roles.length > 0) {
          const hasPermission = window.Roles.some(role => Permissions.includes(role));
          setHasPermission(hasPermission);
        } else {
          if (!pageReloaded) {
            // Reload the page if Roles are not set, but do it only once
            setPageReloaded(true);
            window.location.reload();
          }
        }
      }
    }, [Permissions, pageReloaded]);

    if (Permissions && !hasPermission) {
      return null; // User doesn't have permission or Roles are not set
    }
    /*
    let { projectId } = useContext(ProjectContext); 
    if(projectId == null) {
      const projectInfo = JSON.parse(localStorage.getItem('projectId'));
      projectId = projectInfo;
    }
    if(projectId == null && name == "Project") {
      return null;
    }*/

    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to, Permissions }) => {
    console.log(name, Permissions)
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    if (Permissions && window.Roles) {
      const hasPermission = window.Roles.some(role => Permissions.includes(role));
      
      if (hasPermission) {
        
      } else {
        return null;
      }
    }

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name 
          )}{}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({ location, sidebar, name }) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));
console.log(name);
  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[item]: false}))
    )
    
    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  }
  
  let userInfo = null;
  const result = returnUserData();
  if (result) {
    userInfo = JSON.parse(result);
  }

  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
          href="/"
        >
          {sidebar.isOnRight || <IosPulse />}{" "}
          <span className="align-middle">Compass Fairs</span>{" "}
          {!sidebar.isOnRight || <IosPulse />}
        </a>

        {userInfo && (
          <div className="sidebar-user">
            <img
              src={userInfo.picture || 'https://placehold.co/250x250/lightblue/white?text=C'}
              className="img-fluid rounded-circle mb-2"
              alt={userInfo.name ? userInfo.name : ""}
            />
            <div className="font-weight-bold">
              {userInfo.name ? userInfo.name : ""}
            </div>
            {/*<small>Front-end Developer</small>*/}
          </div>
        )}

        <ul className="sidebar-nav">
          {routes.map((category, index) => {
            console.log(category);
            return (
              <React.Fragment key={index}>
                {category.header ? (
                  <li className="sidebar-header">{category.header}</li>
                ) : null}

                {category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    Permissions={category.Permissions}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                  >
                    {category.children.map((route, index) => (
                      <SidebarItem
                        key={index}
                        name={route.name}
                        to={route.path}
                        badgeColor={route.badgeColor}
                        badgeText={route.badgeText}
                        Permissions={route.Permissions}
                      />
                    ))}
                  </SidebarCategory>
                ) : (
                  <SidebarItem
                    name={category.name}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                  />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
  }))(Sidebar)
);

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Label } from "reactstrap";
import { RingLoader } from "react-spinners";
import { MoreVertical, RefreshCw } from "react-feather";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import ProjectSelector from "./projectSelect.js";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Riggeliste = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRiggeliste, setSelectedRiggeliste] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    console.log(isChecked)
    setIsChecked(!isChecked);
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePrint = () => {
    const printContents = document.getElementById("printTable").outerHTML;
  
    const printWindow = window.open("", "_blank");
  
    // Write the HTML for the print window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="/css/modern.css" rel="stylesheet" type="text/css">');
    printWindow.document.write('<link href="/css/print.css" rel="stylesheet" type="text/css" media="print">');
    printWindow.document.write('<style>');
    printWindow.document.write('.card { page-break-inside: avoid; }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
  
    // Trigger the print operation
   // printWindow.print();
   // printWindow.close();
  };

  const singlePrint = () => {
    const printContents = document.getElementById("printTable").outerHTML;
  
    const printWindow = window.open("", "_blank");
  
    // Write the HTML for the print window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="/css/modern.css" rel="stylesheet" type="text/css">');
    printWindow.document.write('<link href="/css/print.css" rel="stylesheet" type="text/css" media="print">');
    printWindow.document.write('<style>');
    printWindow.document.write('.card { page-break-before: always; }');
    printWindow.document.write('.card { page-break-inside: avoid; }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
  
    // Trigger the print operation
   // printWindow.print();
   // printWindow.close();
  };
  

  useEffect(() => {
    if (selectedProject) {
      const url = `https://api.fairs.as/api/combinedOrders?projectId=${selectedProject.value}`;
      setLoading(true);

      axios
        .get(url)
        .then((response) => {
          setSelectedRiggeliste(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedProject]);

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Rigging list</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Denmark Project</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Rigging list</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Velg prosjekt
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ProjectSelector
                  apiUrl={"https://api.fairs.as"}
                  onProjectSelect={handleProjectSelect}
                />
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {selectedRiggeliste && (
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Filter settings
                </CardTitle>
              </CardHeader>
              <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <Input
                      type="text"
                      placeholder="Filter orders by SKU"
                      className="w-100"
                      value={filter}
                      onChange={handleFilterChange}
                    />


                    <div className="card-actions">
                      <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle tag="a">
                          <MoreVertical />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={handlePrint}>Print</DropdownItem>
                          <DropdownItem onClick={singlePrint}>Single print</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      )}
      {loading ? (
        <div className="loading-screen">
          <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
        </div>
      ) : (
        selectedRiggeliste && (
          <Container fluid className="printTable" id="printTable">
            <Row>
              <Col>
                {selectedRiggeliste.orders?.map((stand, index) => {
                  const matchingOrders = stand.invoiceLines.filter((order) =>
                    order.productNumber.includes(filter)
                  );

                  if (matchingOrders.length === 0) {
                    return null; // Skip cards with no matching orders
                  }

                  return (
                    <Card key={index}>
                      <CardHeader>
                        <div className="d-flex justify-content-between">
                          <div style={{ width: '100%' }}>
                            <span className="mr-3">
                             <strong style={{ fontSize: "19px", fontWeight: 800 }}>{stand.recipientName}</strong>
                            </span>
                            <span className="float-right">
                              <span>Standnr:</span> <strong style={{ fontSize: "19px" }}>{stand.standnr}</strong>
                            </span>
                          </div>
                        </div>

                      <div className="d-flex justify-content-between mt-1">
                        <div style={{ width: '100%' }}>
                          <span className="mr-3">
                            {stand.customerInfo ? (
                              <>
                                <span>Kontaktperson: {stand.customerInfo.name}</span>
                                {stand.customerInfo.email && (
                                  <span> - {stand.customerInfo.email}</span>
                                )}
                                {stand.customerInfo.phone && (
                                  <span> - {stand.customerInfo.phone}</span>
                                )}
                              </>
                            ) : (
                              <span>Customer information not set.</span>
                            )}
                          </span>
                        </div>
                      </div>
                    {stand.text && (
                      <div className="d-flex justify-content-between mt-1">
                          <div style={{ width: '100%' }}>
                            <span className="mr-3">
                              <span style={{color: "grey" }}>{stand.text}</span>
                            </span>
                          </div>
                        </div>
                        )}
                      </CardHeader>
                      <CardBody>
                        <Table id={`printTable-${index}`} className="mb-0 table-striped">
                          <thead>
                            <tr>
                              <th>SKU</th>
                              <th style={{ width: '69%' }}>Description</th>
                              <th style={{ width: '9%' }} >Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchingOrders.map((order, orderIndex) => (
                              <tr key={orderIndex}>
                                <td>{order.productNumber}</td>
                                <td>{order.description}</td>
                                <td>{order.quantity}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  );
                })}
              </Col>
            </Row>
          </Container>
        )
      )}
    </>
  );
};

export default Riggeliste;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

const ProjectSelector = ({ apiUrl, onProjectSelect }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check if there's a selected project in localStorage
    const savedProject = localStorage.getItem("selectedProject");
    if (savedProject) {
      setSelectedProject(JSON.parse(savedProject));
    }
    
    loadOptions("");
  }, []);

  const handleFetchData = () => {
    if (selectedProject) {
      // Save the selected project to localStorage
      localStorage.setItem("selectedProject", JSON.stringify(selectedProject));
      onProjectSelect(selectedProject);
    }
  };

  const loadOptions = async (inputValue) => {
    setIsLoading(true);
    try {
      const dynamicUrl = `${apiUrl}/nb/wp-json/RJSDashboard/v1/getProjects`;
      const response = await axios.get(dynamicUrl);

      const projectOptions = response.data
        .filter(
          (item) =>
            item.id.toString().includes(inputValue) ||
            item.title.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => ({
          value: item.id,
          label: item.title,
          pid : item.pid,
        }));

      setProjectOptions(projectOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Select
        options={projectOptions}
        isClearable
        onChange={setSelectedProject}
        value={selectedProject}
        isSearchable
        placeholder="Select or search for a project..."
        isMulti={false}
        isLoading={isLoading}
      />
      <div className="my-3 "></div>
      <button onClick={handleFetchData} className="btn btn-primary">
        Hent data
      </button>
    </div>
  );
};

export default ProjectSelector;

import React, { useState, useCallback, useRef } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FaCamera } from 'react-icons/fa';
import Webcam from 'react-webcam';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
  facingMode: FACING_MODE_USER,
};

const CameraComponent = ({ folder }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [enlargedPicture, setEnlargedPicture] = useState(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const webcamRef = useRef(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const switchCamera = useCallback(() => {
    setFacingMode(prevState =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  }, []);

  const takePicture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      fetch(imageSrc)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], `photo-${Date.now()}.jpg`, { type: 'image/jpeg' });
          setPictures([...pictures, file]);
        });
    }
  }, [pictures]);

  const removePicture = (index) => {
    const updatedPictures = pictures.filter((_, i) => i !== index);
    setPictures(updatedPictures);
  };

  const enlargePicture = (src) => {
    const reader = new FileReader();
    reader.onload = (e) => setEnlargedPicture(e.target.result);
    reader.readAsDataURL(src);
  };

  const sendPictures = async () => {
    try {
      const formData = new FormData();
      pictures.forEach((picture) => {
        formData.append('images', picture, picture.name);
      });

      formData.append('FolderLocation', folder);

      const response = await fetch('https://api2.fairs.as/ta-orders/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Pictures sent successfully');
        setPictures([]);
      } else {
        console.error('Failed to send pictures');
      }
    } catch (error) {
      console.error('Error sending pictures:', error);
    }
  };

  return (
    <span className="mr-2" style={{ fontSize: 'x-large' }}>
      <FaCamera onClick={toggleModal} />
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          <span style={{ fontSize: '1.5rem' }}>Camera</span>
        </ModalHeader>
        <ModalBody>
          <div style={{ maxWidth: '100%', overflow: 'hidden', marginBottom: '10px', textAlign: 'center' }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints,
                facingMode,
              }}
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
            <Button color="success" onClick={takePicture}>Take Picture</Button>
            <Button color="warning" onClick={switchCamera}>Switch Camera</Button>
            <Button color="info" onClick={sendPictures}>Send</Button>
          </div>
          <div className="pictures" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {pictures.map((picture, index) => (
              <Snapshot
                key={index}
                src={picture}
                index={index}
                removePicture={removePicture}
                enlargePicture={enlargePicture}
              />
            ))}
          </div>
          <Modal isOpen={!!enlargedPicture} toggle={() => setEnlargedPicture(null)} size="lg">
            <ModalHeader toggle={() => setEnlargedPicture(null)}>Enlarged Picture</ModalHeader>
            <ModalBody>
              <img src={enlargedPicture} alt="Enlarged" style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
          </Modal>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default CameraComponent;

const Snapshot = ({ src, index, removePicture, enlargePicture }) => {
  const removeHandler = () => {
    removePicture(index);
  };

  const enlargeHandler = () => {
    enlargePicture(src);
  };

  return (
    <div style={{ margin: '5px', position: 'relative' }}>
      <img
        src={URL.createObjectURL(src)}
        alt={`Snapshot ${index}`}
        style={{ width: '100px', height: 'auto', cursor: 'pointer' }}
        onClick={enlargeHandler}
      />
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          cursor: 'pointer',
          fontSize: '20px',
          backgroundColor: 'rgba(255,255,255,0.7)',
          borderRadius: '50%'
        }}
        onClick={removeHandler}
      >
        &times;
      </div>
    </div>
  );
};

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDDlnE4-zWWTdcB3Frm5NRADvaXerdTUIo",
  authDomain: "expopush-ed2f2.firebaseapp.com",
  projectId: "expopush-ed2f2",
  storageBucket: "expopush-ed2f2.appspot.com",
  messagingSenderId: "500396724856",
  appId: "1:500396724856:web:c101a19a148ff8d279938d",
  measurementId: "G-Y33J331BFT"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
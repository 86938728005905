import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardTitle,
  Table,
  Button
} from "reactstrap";
import { RefreshCw } from "react-feather";
import { ProjectContext } from "../../../context/ProjectContext";

const ProjectComponent = () => {
  const [projects, setProjects] = useState([]);
  const { projectId, updateProjectId } = useContext(ProjectContext);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const localStorageProject = localStorage.getItem('projectId');
    if (localStorageProject) {
      updateProjectId(localStorageProject);
    }
  }, [updateProjectId]);

  useEffect(() => {
    refreshProjects();
  }, []);

  const refreshProjects = () => {
    setRefreshing(true);
    fetch("https://compassfairs.no/wp-json/native-app/v1/projects")
      .then(response => response.json())
      .then(data => {
        setProjects(data);
        setRefreshing(false);
      })
      .catch(error => {
        console.error("Error fetching projects:", error);
        setRefreshing(false);
      });
  };

  const handleProjectSelect = (selectedProjectId, projectName) => {
    updateProjectId(selectedProjectId);
    localStorage.setItem('projectId', selectedProjectId);
    localStorage.setItem('projectName', projectName);
  };
  
  return (
    <Card className="flex-fill w-100">
      <CardHeader>
        <div className="card-actions float-right">
          <span
            className={`cursor-pointer mr-1 ${refreshing ? "rotate" : ""}`}
            onClick={refreshProjects}
          >
            <RefreshCw />
          </span>
        </div>
        <CardTitle tag="h5" className="mb-0">
          Aktive Prosjekter
        </CardTitle>
      </CardHeader>
      <Table striped className="my-0">
        <thead>
          <tr>
            <th>Title</th>
            <th>Project ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {projects.map(project => (
            <tr key={project.projectId}>
              <td>{project.Title}</td>
              <td>{project.projectId}</td>
              <td>
                {projectId === project.projectId ? (
                  <span>Selected</span>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => handleProjectSelect(project.projectId, project.Title)}
                  >
                    {projectId == project.projectId ? "Selected" : "Select"}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default ProjectComponent;

import axios from "axios";
import Select from "react-select";

export const columns = [
    {
      dataField: 'uid',
      text: 'UUID'
    },
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'category',
      text: 'Category',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const handleCategorySelect = (selectedOption) => {
          const updatedRow = {
            ...row,
            category: selectedOption,
          };
          formatExtraData.handleCategoryChange(updatedRow);
        };
  
        const initialValue = row && row.category ? row.category : null;
  
        return (
          <Select
            options={[
              { value: 'electric', label: 'Electric' },
              { value: 'floor', label: 'Floor' },
              { value: 'ceiling', label: 'Ceiling' }
            ]}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            placeholder="Select category"
            onChange={handleCategorySelect}
            value={initialValue}
          />
        );
      }
    }
  ];




export const fetchStoredProjects = async (setProjectOptions, setIsLoading) => {
  try {
    const apiUrl = "https://api2.fairs.as/ta-orders/currentProjects";
    const response = await axios.get(apiUrl);
    setProjectOptions(response.data);
  } catch (error) {
    console.error("Error fetching stored projects:", error);
  } finally {
    setIsLoading(false);
  }
};

export const fetchAssemblers = async (setAssemblers) => {
  try {
    const apiUrl = "https://api2.fairs.as/auth/assemblers";
    const response = await axios.get(apiUrl);
    setAssemblers(response.data);
  } catch (error) {
    console.error("Error fetching assemblers:", error);
  }
};

const roles = {
    Administrator: {
      permissions: ['*'],
    },
    Fairs: {
      permissions: [
        'Access Ordrerapport',
        'Access Utstillerrapport',
        'Access Billettrapport',
        'Access SMS',
      ],
    },
    'Expo Solutions': {
      permissions: [
        'Access Ordrerapport',
        'Access Utstillerrapport',
        'Access Salgsrapport',
        'Access Avviksrapport',
        'Access Rigge og pakkeliste',
        'Access Monteringsapp',
      ],
    },
    Messe: {
      permissions: [
        'Access SMS',
        'Access Besøkende',
        'Access Utstillere',
        'Access Linkutsendelse',
        'Access Dagsrapport messe',
      ],
    },
  };
  
  export default roles;
  
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react"; 
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import { RingLoader } from "react-spinners"; 

import { MoreVertical, RefreshCw } from "react-feather";

import { jsPDF } from "jspdf";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import ProjectSelector from "./projectSelect.js";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Pakkeliste = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedPakkeListe, setSelectedPakkeListe] = useState(null);
  const [loading, setLoading] = useState(false); // Track loading state
  const [filter, setFilter] = useState(""); // Track filter value

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePrint = () => {
    // Get the HTML content of the table
    const printContents = document.getElementById("printTable").outerHTML;
  
    // Open a new window for printing
    const printWindow = window.open('', '_blank');
  
    // Write the table content and link to the external CSS file to the new window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="/css/modern.css" rel="stylesheet" type="text/css">');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
  
    // Trigger the print operation after the CSS has loaded
    printWindow.document.getElementsByTagName("link")[0].onload = function () {
      printWindow.print();
      printWindow.close();
    };
  };

  const handlePrint2 = () => {
    // Get the HTML content of the table
    const printContents = document.getElementById("printTable").outerHTML;
  
    // Create a new jsPDF instance
    const pdf = new jsPDF();
  
    // Add the HTML content to the PDF
    pdf.html(printContents, {
      callback: function (pdf) {
        // Save or display the PDF, for example:
        // pdf.save("printed-table.pdf");
        pdf.output("dataurlnewwindow");
      },
    });
  };
  

  useEffect(() => {
    if (selectedProject) {
      // Construct the URL with the projectId from selectedProject
      const url = `https://api.fairs.as/api/pakkeliste?projectId=${selectedProject.value}`;
      
      // Set loading to true when starting the request
      setLoading(true);

      axios
        .get(url)
        .then((response) => {
          setSelectedPakkeListe(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // Set loading to false when the request is complete
          setLoading(false);
        });
    }
  }, [selectedProject]);

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Pakkeliste</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Denmark Project</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Pakkeliste</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Select Project
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ProjectSelector
                  apiUrl={"https://api.fairs.as"}
                  onProjectSelect={handleProjectSelect}
                />
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading ? ( // Conditionally render loading screen
        <div className="loading-screen">
          <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
        </div>
      ) : (
        selectedPakkeListe && (
          <Container fluid>
            <Row>
              <Col>
                <Card>
                      <CardHeader>
                <div className="card-actions float-right">
                { /*  <span className="cursor-pointer mr-1">
                    <RefreshCw />
        </span>{" "} */}
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle tag="a">
                      <MoreVertical />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={handlePrint}>Print</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <CardTitle tag="h5" className="mb-0">
                  Results
                </CardTitle>
              </CardHeader>
                  <CardBody>
                    <Input
                      type="text"
                      placeholder="Filter by SKU"
                      value={filter}
                      onChange={handleFilterChange}
                    />
                    <Table id="printTable" className="mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>SKU</th>
                          <th>Description</th> 
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPakkeListe.orders?.map((order, index) => {
                          // Filter the items based on the SKU
                          if (filter === "" || order.productNumber.includes(filter)) {
                            return (
                              <tr key={index}>
                                <td>{order.productNumber}</td>
                                <td>{order.description}</td>
                                <td>{order.quantity}</td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )
      )}
    </>
  );
};

export default Pakkeliste;

import { useHistory } from "react-router-dom";

export const useNavigateToPage = () => {
  const history = useHistory();

  const navigateToPage = (path) => {
    if (path) {
      history.push(path);
    }
  };

  return navigateToPage;
};

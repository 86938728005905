import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

const AddUserModal = ({
  isOpen,
  toggleModal,
  newUserData,
  handleInputChange,
  addUser,
  userRole
}) => {
  // Define the available roles as a constant array
  const availableRoles = ['Administrator', 'Admin-DK', 'Fairs', 'Expo Solutions', 'Messe', 'Media', 'Assembler', 'Chief Assembler', 'External Assembler'];

  const [selectedRoles, setSelectedRoles] = useState([]);

  // Function to handle role selection
  const handleRoleChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setSelectedRoles(selectedOptions);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Add New User</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstName">Firstname</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={newUserData.firstName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Lastname</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              value={newUserData.lastName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={newUserData.email}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mobile">Mobile</Label>
            <Input
              type="text"
              name="mobile"
              id="mobile"
              value={newUserData.mobile}
              onChange={handleInputChange}
              placeholder="Telephone Number 4794725378"
            />
          </FormGroup>
          <FormGroup>
            <Label for="roles">User Roles</Label>
            <Input
              type="select"
              name="roles"
              id="roles"
              multiple
              value={selectedRoles}
              onChange={handleRoleChange}
            >
              {availableRoles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="country">Country</Label>
            <Input
              type="text"
              name="country"
              id="country"
              value={newUserData.country}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => addUser(selectedRoles)}>
          Add User
        </Button>{' '}
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserModal;

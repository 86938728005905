import React, { createContext, useContext, useState } from "react";

const UserDataContext = createContext();

export const UserDataProvider = ({ children, userData: initialUserData }) => {
  const [userData, setUserData] = useState(initialUserData);

  const setUserDataInContext = (newUserData) => {
    setUserData(newUserData);
  };

  return (
    <UserDataContext.Provider value={{ userData, setUserDataInContext }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  return useContext(UserDataContext);
};

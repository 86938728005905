import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { json2csv } from 'json2csv';
import * as XLSX from 'xlsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { MoreVertical } from 'react-feather'; 
import { parse } from 'json2csv'; 

const SalesOppTable = () => {
  const [salesOpps, setSalesOpps] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const getUnixTimestamp = () => Math.floor(Date.now() / 1000);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api2.fairs.as/24so/soap/salesOpp');
        if (!response.ok) {
          throw new Error('Failed to fetch sales opportunities');
        }
        const data = await response.json();
        setSalesOpps(data);
      } catch (error) {
        console.error('Error fetching sales opportunities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const exportToCSV = (salesOpps) => {
    try {
      const csvData = salesOpps.map((opp) => {
        const company = opp.company || {};
        const relations = company.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === opp.CustomerContactId,
        );
  
        return {
          Company: company.Name || 'N/A',
          Stand: opp.Description || 'N/A',
          ExternalId: company.ExternalId || 'N/A',
          OrgNr: company.OrganizationNumber || 'N/A',
          FirstName: relevantRelation?.FirstName || 'N/A',
          LastName: relevantRelation?.LastName || 'N/A',
          Phone: relevantRelation?.PhoneNumbers?.Mobile?.Value || 'N/A',
          Email: relevantRelation?.EmailAddresses?.Work?.Value || 'N/A',
        };
      });
      const timestamp = getUnixTimestamp();
      const csv = parse(csvData); // Parse the structured data into CSV
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `Export_${timestamp}.csv`); // Save with unique name
    } catch (error) {
      console.error('Error exporting CSV:', error); // Error handling
    }
  };

  const exportToXLS = (salesOpps) => {
    try {
      const xlsData = salesOpps.map((opp) => {
        const company = opp.company || {};
        const relations = company.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === opp.CustomerContactId,
        );
  
        return {
          Company: company.Name || 'N/A',
          Stand: opp.Description || 'N/A',
          ExternalId: company.ExternalId || 'N/A',
          OrgNr: company.OrganizationNumber || 'N/A',
          FirstName: relevantRelation?.FirstName || 'N/A',
          LastName: relevantRelation?.LastName || 'N/A',
          Phone: relevantRelation?.PhoneNumbers?.Mobile?.Value || 'N/A',
          Email: relevantRelation?.EmailAddresses?.Work?.Value || 'N/A',
        };
      });
  
      const worksheet = XLSX.utils.json_to_sheet(xlsData); // Create Excel worksheet
      const workbook = XLSX.utils.book_new(); // Create a new workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'SalesOpp'); // Append the worksheet
  
      const binaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
      const buffer = new ArrayBuffer(binaryString.length);
      const view = new Uint8Array(buffer);
  
      for (let i = 0; i < binaryString.length; i++) {
        view[i] = binaryString.charCodeAt(i); // Convert binary to Uint8Array
      }const timestamp = getUnixTimestamp();
  
      const blob = new Blob([buffer], { type: 'application/octet-stream' }); // Create a Blob
      saveAs(blob, `Export_${timestamp}.xlsx`); // Save with unique name
    } catch (error) {
      console.error('Error exporting XLS:', error); // Error handling
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Sales Opportunities</BreadcrumbItem>
          </Breadcrumb>

          <Card>
            <CardHeader>
              <div className="card-actions float-right">
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle tag="a">
                    <MoreVertical />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => exportToCSV(salesOpps)}>Export to CSV</DropdownItem>
                    <DropdownItem onClick={() => exportToXLS(salesOpps)}>Export to XLS</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <CardTitle tag="h5" className="mb-0">
                Sales Opportunities
              </CardTitle>
            </CardHeader>

            {loading ? (
              <div>Loading...</div>
            ) : (
              <Table striped>
                <thead>
                  <tr>
                    <th>Firma</th>
                    <th>Stand-NR</th>
                    <th>EksternID</th>
                    <th>Orgnr</th>
                    <th>Fornavn</th>
                    <th>Eternavn</th>
                    <th>Tlf</th>
                    <th>E-post</th>
                  </tr>
                </thead>
                <tbody>
                  {salesOpps.map((opp, index) => {
                    const company = opp.company || {}; // Safe check for company info
                    const relations = company.Relations?.Relation || []; // Safe check for relations
                    const relevantRelation = relations.find(
                      (relation) => relation.ContactId === opp.CustomerContactId,
                    );

                    return (
                      <tr key={index}>
                        <td>{company.Name || 'N/A'}</td>
                        <td>{opp.Description || 'N/A'}</td>
                        <td>{company.ExternalId || 'N/A'}</td>
                        <td>{company.OrganizationNumber || 'N/A'}</td>
                        <td>{relevantRelation?.FirstName || 'N/A'}</td>
                        <td>{relevantRelation?.LastName || 'N/A'}</td>
                        <td>{relevantRelation?.PhoneNumbers?.Mobile?.Value || 'N/A'}</td>
                        <td>{relevantRelation?.EmailAddresses?.Work?.Value || 'N/A'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card>
        </Col>
      </Row>
      <br/>
    </Container>
  );
};

export default SalesOppTable;

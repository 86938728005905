import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label, Col } from 'reactstrap';
import CameraComponent from './CameraComponent';
import { connect } from 'react-redux';
import axios from 'axios';
import ReduxToastr, { actions as toastrActions } from 'react-redux-toastr';
import Select from 'react-select';

const AddTaskModal = ({ isOpen, toggle, inputValue1, inputValue2, projectPath, dispatch }) => {
  const [companyName, setCompanyName] = useState(inputValue1[0] || '');
  const [standNr, setStandNr] = useState(inputValue2[0] || '');
  const [textareaValue, setTextareaValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const storedProject = JSON.parse(localStorage.getItem("erpProject"));
  const categoryOptions = [ 
    { value: "Flooring", label: "Flooring" },
    { value: "Storage", label: "Storage" },
    { value: "Furniture", label: "Furniture" },
    { value: "Stand profiling, walls & front boards", label: "Stand profiling, walls & front boards" },
    { value: "Starter packs", label: "Starter packs" },
    { value: "Power and Light", label: "Power and Light" },
    { value: "Accessories & small electrical", label: "Accessories & small electrical" },
  ];

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const handleCategorySelect = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  const handleSubmit = async () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')} ${currentDate.getDate().toString().padStart(2, '0')}.${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;


    const dataToSend = {
      erp_projectNumber: `${storedProject.value}`, 
      t_newCategory: selectedCategories.map(category => category.label),
      companyName: inputValue1[0],
      t_standnumber: inputValue2[0],
      description: textareaValue,
      t_status: "pending",
      createdby: userData.name,
      picture: userData.picture,
      t_created: formattedDate,
    };

    try {
      const response = await axios.post('https://api2.fairs.as/ta-orders/createTask', dataToSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status === 'success') {
        dispatch(
          toastrActions.add({
            type: 'success',
            title: 'Task Created',
            message: 'The task has been created successfully.',
          })
        );
        setSelectedCategories('');
        setTextareaValue('');
        toggle(); // Close the modal after saving
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error sending POST request:', error);
      dispatch(
        toastrActions.add({
          type: 'error',
          title: 'Error',
          message: 'An error occurred while creating the task.',
        })
      );
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Add Task</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label for="companyName" sm={2}>Company</Label>
          <Col sm={9}>
            <Input
              type="text"
              id="companyName"
              value={inputValue1[0]}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="standNr" sm={2}>Standnr</Label>
          <Col sm={9}>
            <Input
              type="text"
              id="standNr"
              value={inputValue2[0]}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="textarea" sm={2}>Task Details</Label>
          <Col sm={9}>
            <Input
              type="textarea"
              id="textarea"
              value={textareaValue}
              onChange={handleTextareaChange}
              placeholder="Enter task details"
              rows="4"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="categorySelect" sm={2}>Category</Label>
          <Col sm={9}>
            <Select
              options={categoryOptions}
              isMulti
              closeMenuOnSelect={false}
              isClearable
              placeholder="Select categories"
              onChange={handleCategorySelect}
              value={selectedCategories}
            />
          </Col>
        </FormGroup>
        {userData && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            {userData.picture && (
              <img src={userData.picture} alt="User Avatar" className="avatar rounded-circle mr-2" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
            )}
            <span>{userData.name}</span>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <span style={{ cursor: 'pointer' }}>
          <CameraComponent folder={`${projectPath}/${companyName} - (${standNr})/tasks`} />
        </span>
        <Button color="primary" onClick={handleSubmit}>Create Task</Button>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect()(AddTaskModal);

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleAuthProvider, OAuthProvider, signInWithPopup, EmailAuthProvider, linkWithCredential, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { useUserData } from "../../context/UserDataContext";
import axios from "axios";
import { GoogleLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";
import "./loginDisplay.css";

const allowedDomains = ['boligmesse.no', 'compassfairs.com', 'compassfairs.no', 'travelxpo.no', 'compassfairs.dk'];

const isAllowedDomain = (email) => {
  const userEmailDomain = email.split('@')[1];
  return allowedDomains.includes(userEmailDomain);
};

  const authHandler = (err, data) => {
    console.log(err, data);
  };

export default function SignIn() {
  const history = useHistory();
  const { setUserDataInContext } = useUserData();

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const redirectToDashboard = () => {
    history.push("/dashboard");
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setErrorMessage("");
    try {
      const result = await signInWithPopup(auth, provider);
      const signedInUser = result.user;

      if (!isAllowedDomain(signedInUser.email)) {
        setErrorMessage("Invalid domain");
        return;
      }

      const response = await axios.post(
        `https://api2.fairs.as/auth/send-otp/${signedInUser.uid}`,
        { uid: signedInUser.uid }
      );

      if (response.status === 201) {
        setUser(signedInUser);
        setOtpSent(true);
      } else {
        setErrorMessage("Error sending OTP");
      }
    } catch (error) {
      setErrorMessage("Google Sign-In Error");
    }
  };

  const handleMicrosoftSignIn = async () => {
    const provider = new OAuthProvider('microsoft.com');
    setErrorMessage("");

    try {
        const result = await signInWithPopup(auth, provider);
        const signedInUser = result.user;

        if (!isAllowedDomain(signedInUser.email)) {
            setErrorMessage("Invalid domain");
            return;
        }

        const response = await axios.post(
            `https://api2.fairs.as/auth/send-otp/${signedInUser.uid}`,
            { uid: signedInUser.uid }
        );

        if (response.status === 201) {
            setUser(signedInUser);
            setOtpSent(true);
        } else {
            setErrorMessage("Error sending OTP");
        }
    } catch (error) {
        if (error.code === 'auth/account-exists-with-different-credential') {
            const email = error.customData?.email || JSON.parse(error.customData._tokenResponse.rawUserInfo).email;

            const userConfirmedLinking = window.confirm(
                `An account already exists with this email (${email}). Do you want to link it to your Microsoft account?`
            );

            if (userConfirmedLinking) {
                try {
                    // Sign in with the existing email/password
                    const existingUserCredential = await signInWithEmailAndPassword(
                        auth,
                        email,
                        "CompassTechnology101"
                    );

                    // Link the Microsoft credential
                    const microsoftCredential = OAuthProvider.credentialFromError(error);
                    await linkWithCredential(existingUserCredential.user, microsoftCredential);

                    // Proceed with your usual flow (send OTP, etc.)
                    const otpResponse = await axios.post(
                        `https://api2.fairs.as/auth/send-otp/${existingUserCredential.user.uid}`,
                        { uid: existingUserCredential.user.uid }
                    );

                    if (otpResponse.status === 201) {
                        setUser(existingUserCredential.user);
                        setOtpSent(true);
                    } else {
                        setErrorMessage("Error sending OTP");
                    }
                } catch (linkError) {
                    setErrorMessage("Error linking accounts. Please check your credentials or contact support.");
                    console.error(linkError);
                }
            } else {
                await auth.signOut();
                setErrorMessage("Linking cancelled. Please sign in with a different account or create a new one.");
            }
        } else {
            setErrorMessage("Microsoft Sign-In Error");
            console.error(error);
        }
    }
};


  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    const response = await axios.post(
      `https://api2.fairs.as/auth/verify-otp/${user.uid}`,
      { otp }
    );

    if (response.status === 201 && response.data.valid) {
      setUserDataInContext(response.data.DB);
      localStorage.setItem("userData", JSON.stringify(response.data.DB));
      history.push("/dashboard");
    } else {
      setErrorMessage("OTP verification failed");
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserDataInContext(userData);
      redirectToDashboard();
    }
  }, [setUserDataInContext]);

  return (
    <div className="login-container">
      <img
        alt="Logo"
        className="logo"
        src="https://compassfairs.no/wp-content/themes/dinstand-new/assets/img/compassfairs_logo.svg"
      />
      <main className="main h-100 w-100">
        <div className="container h-100">
          <div className="row h-100 d-flex align-items-center justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card login-card">
                <div className="card-body">
                  <h1 className="h1Login text-center">Logg inn</h1>
                  {errorMessage && (
                    <div className="alert alert-danger text-center">{errorMessage}</div>
                  )}
                  {!otpSent ? (
                    <div className="text-center">
                      <GoogleLoginButton onClick={handleGoogleSignIn} align="center">
                        <span>Fortsett med Google</span>
                      </GoogleLoginButton>
                      <MicrosoftLoginButton onClick={handleMicrosoftSignIn} align="center" style={{background:"#ffffff"}}>
                        <span style={{color:"black"}}>Fortsett med Microsoft</span>
                      </MicrosoftLoginButton>
                    </div>
                  ) : (
                    <form onSubmit={handleOtpSubmit} className="text-center">
                      <div className="mb-3">
                        <label htmlFor="otp" className="form-label">Vennligst skriv inn engangskoden som ble sendt via SMS.</label>
                        <input
                          className="form-control"
                          type="text"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-success">
                        Verify OTP
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from "react-select";

const EditUserModal = ({
  isOpen,
  toggleModal,
  uid,
  addUser,
}) => {
  const availableRoles = ['Administrator', 'Admin-DK', 'Fairs', 'Expo Solutions', 'Messe', 'Media', 'Assembler', 'Chief Assembler', 'External Assembler'];

  const [newUserData, setNewUserData] = useState({
    name: '',
    email: '',
    mobile: '',
    country: '',
  });
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (isOpen && uid) {
      // Fetch user data when the modal is opened
      fetch(`https://api2.fairs.as/auth/user/${uid}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          return response.json();
        })
        .then((data) => {
          const { firstName, lastName, roles, mobile,number, country } = data;
          console.log(data);
          setNewUserData({
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            country: country ?? '', // Set as needed
          });
          setSelectedRoles(roles); // Populate selectedRoles with existing roles
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [isOpen, uid]); // Depend on the modal's open state and UID

  const handleRoleChange = (selectedOptions) => {
    const roles = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setSelectedRoles(roles);
  };

  const handleSaveChanges = () => {
    const updateData = {
      ...newUserData,
      roles: selectedRoles, // Ensure roles are included in the update
    };

    fetch(`https://api2.fairs.as/auth/user/${uid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update user');
        }
        return response.json();
      })
      .then((data) => {
        console.log('User updated successfully:', data);
        toggleModal(); // Close the modal after a successful update
      })
      .catch((error) => {
        console.error('Error updating user:', error);
      });
  };

  console.log(newUserData);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Edit User</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstName">Fist name</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={newUserData.firstName}
              onChange={(e) => setNewUserData({ ...newUserData, firstName: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="name">Last name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={newUserData.lastName}
              onChange={(e) => setNewUserData({ ...newUserData, lastName: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mobile">Mobile</Label>
              <Input
                type="text"
                name="mobile"
                id="mobile"
                value={newUserData.mobile}
                onChange={(e) => setNewUserData({ ...newUserData, mobile: e.target.value })}
                placeholder="Telephone number"
              />
          </FormGroup>
          <FormGroup>
            <Label for="roles">User roles</Label>
            <Select
              isMulti
              options={availableRoles.map((role) => ({ value: role, label: role }))}
              value={selectedRoles.map((role) => ({ value: role, label: role }))}
              onChange={handleRoleChange}
              placeholder="Select User Roles"
            />
          </FormGroup>
          <FormGroup>
            <Label for="country">Country</Label>
              <Input
                type="text"
                name="country"
                id="country"
                value={newUserData.country}
                onChange={(e) => setNewUserData({ ...newUserData, country: e.target.value })}
              />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditUserModal;

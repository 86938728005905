import ReduxToastr, { actions as toastrActions } from 'react-redux-toastr'; 
import { useDispatch } from 'react-redux';

const useDynamicToastr = () => {
  const dispatch = useDispatch();

  const showToastr = (type, title, message) => {
    dispatch(
      toastrActions.add({
        type,
        title,
        message,
      })
    );
  };

  return showToastr;
};

export default useDynamicToastr;

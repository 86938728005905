import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const cache = {};

const PhotoGallery = (({ folder }) => {
  const [photos, setPhotos] = useState([]);
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const galleryRef = useRef(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      if (cache[folder]) {
        const cachedData = cache[folder];
        setPhotos(cachedData.photos);
        setImages(cachedData.images);
        return;
      }

      try {
        const response = await fetch(`https://api2.fairs.as/ta-orders/fetchPhotos?path=${encodeURIComponent(folder)}`);
        if (response.ok) {
          const data = await response.json();
          const formattedPhotos = Object.values(data).map(url => ({
            original: url,
            thumbnail: url,
          }));
          cache[folder] = { photos: Object.values(data), images: formattedPhotos };
          setPhotos(Object.values(data));
          setImages(formattedPhotos);
        }
      } catch (error) {
        console.error('Error fetching photos:', error);
      }
    };

    fetchPhotos();
  }, [folder]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      // Open in fullscreen mode when modal opens
      setTimeout(() => {
        galleryRef.current.fullScreen();
      }, 100);
    }
  };

  return (
    <>
      {photos.length > 0 && (
        <FontAwesomeIcon icon={faCheck} style={{ color: 'green', cursor: 'pointer' }} onClick={toggleModal} />
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal} centered size="lg" style={{ maxWidth: '80%', maxHeight: '80%' }}>
        <ModalHeader>
          <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={toggleModal} />
        </ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <ImageGallery
            ref={galleryRef}
            items={images}
            showFullscreenButton={false}  // Disable built-in fullscreen button
            showPlayButton={false}
            showThumbnails={true}
            thumbnailPosition="bottom"
          />
        </ModalBody>
      </Modal>
    </>
  );
});

export default PhotoGallery;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../../src/components/Header";
import HeaderTitle from "../../../src/components/HeaderTitle";

const ProjectSelector = ({ onProjectSelect }) => {
  const [assemblerAppProject, setAssemblerAppProject] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [kioskProject, setKioskProject] = useState(null);
  const [localDeviceProject, setLocalDeviceProject] = useState(null);

  useEffect(() => {
    loadOptions("");
    fetchStoredProjects();
    initializeLocalDeviceProject();
  }, []);

  useEffect(() => {
    if (projectOptions.length > 0) {
      fetchKioskProject();
    }
  }, [projectOptions]);

  const loadOptions = async (inputValue) => {
    setIsLoading(true);
    try {
      const apiUrl = "https://api2.fairs.as/24so/soap/projects";
      const response = await axios.get(apiUrl);

      const projectOptions = response.data.Project
        .filter(
          (item) =>
            item.Id.toString().includes(inputValue) ||
            item.Name.toLowerCase().includes(inputValue.toLowerCase()),
        )
        .map((item) => ({
          value: item.Id,
          label: item.Name,
          type: item.TypeName
        }));

      setProjectOptions(projectOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStoredProjects = async () => {
    try {
      const apiUrl = "https://api2.fairs.as/ta-orders/currentProjects";
      const response = await axios.get(apiUrl);
      setAssemblerAppProject(response.data);
    } catch (error) {
      console.error("Error fetching stored projects:", error);
    }
  };

  const fetchKioskProject = async () => {
    try {
      const apiUrl = "https://compassfairs.no/wp-json/fairsas/getKioskWP";
      const response = await axios.get(apiUrl, {
        auth: {
          username: 'admin',
          password: 'admin',
        },
      });
      
      const { erp_projectId } = response.data;

      if (erp_projectId) {
        const project = projectOptions.find(option => option.value === parseInt(erp_projectId));
        console.log(project); // Should log the project if found
        setKioskProject(project || null);
      }
    } catch (error) {
      console.error("Error fetching kiosk project:", error);
    }
  };

  const handleProjectChange = async (selectedOptions) => {
    setAssemblerAppProject(selectedOptions);
    if (selectedOptions && selectedOptions.length > 0) {
      try {
        for (const selectedOption of selectedOptions) {
          const projectId = selectedOption.value;
          const apiUrl = `https://api2.fairs.as/ta-orders/currentProject/${projectId}`;
          await axios.post(apiUrl, selectedOption);
        }
        toastr.success("Success", "Projects added successfully!");
      } catch (error) {
        console.error("Error sending data:", error);
        toastr.error("Error", "Failed to select projects.");
      }
    }
  };

  const handleKioskProjectChange = async (selectedOption) => {
    setKioskProject(selectedOption);
    if (selectedOption) {
      try {
        const apiUrl = `https://compassfairs.no/wp-json/fairsas/setKioskWP`;
        const response = await axios.get(apiUrl, {
          params: { pid: selectedOption.value },
          auth: {
            username: 'admin',
            password: 'admin',
          },
        });

        const { project_id, updated } = response.data;

        if (updated) {
          toastr.success("Success", `Kiosk Project updated successfully to ${project_id}`);
        } else {
          toastr.error("Error", "Failed to update Kiosk Project.");
        }
      } catch (error) {
        console.error("Error updating kiosk project:", error);
        toastr.error("Error", "Failed to update Kiosk Project.");
      }
    }
  };

  const handleDeleteProject = async (project) => {
    try {
      const projectId = project.value;
      const apiUrl = `https://api2.fairs.as/ta-orders/currentProject/${projectId}`;
      await axios.delete(apiUrl);

      setAssemblerAppProject((prevProjects) =>
        prevProjects.filter((p) => p.value !== projectId)
      );

      toastr.success("Warning", `Project ${project.label} deleted successfully!`);
    } catch (error) {
      console.error("Error deleting project:", error);
      toastr.error("Error", `Failed to delete project ${project.label}.`);
    }
  };

  const customComponents = {
    MultiValueRemove: ({ innerRef, data }) => (
      <div ref={innerRef} className="react-select__multi-value__remove" onClick={() => handleDeleteProject(data)}>
        &times;
      </div>
    ),
  };

  const handleFetchData = () => {
    if (assemblerAppProject.length > 0) {
      onProjectSelect(assemblerAppProject);
    }
  };

  const handleLocalDeviceProjectChange = (selectedOption) => {
    setLocalDeviceProject(selectedOption);
    if (selectedOption) {
      const { value, label, type } = selectedOption;
      localStorage.setItem("erpProject", JSON.stringify({ value, label, type }));
    } else {
      localStorage.removeItem("erpProject");
    }
  };

  const initializeLocalDeviceProject = () => {
    const storedProject = localStorage.getItem("erpProject");
    if (storedProject) {
      const { value, label, type } = JSON.parse(storedProject);
      setLocalDeviceProject({ value, label, type });
    }
  };

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>Settings</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Assembler App</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Settings</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Select Projects for Assembler App - (Sets Active Projects in the app)
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Select
                      options={projectOptions}
                      isClearable
                      components={customComponents}
                      onChange={handleProjectChange}
                      value={assemblerAppProject}
                      isSearchable
                      placeholder="Select or search for projects..."
                      isMulti
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/*
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Kiosk Project (Communicates with Wordpress)
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Select
                      options={projectOptions}
                      isClearable
                      components={customComponents}
                      onChange={handleKioskProjectChange}
                      value={kioskProject}
                      isSearchable
                      placeholder="Select or search for projects..."
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>*/}

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Local device project controller (Dashboard, Tasks, Checklist, Packing List, Assemblers and Products.... )
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex p-2">
                  <div className="flex-grow-1">
                    <Select
                      options={projectOptions}
                      isClearable
                      components={customComponents}
                      onChange={handleLocalDeviceProjectChange}
                      value={localDeviceProject}
                      isSearchable
                      placeholder="Select or search for projects..."
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectSelector;

import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";
import { ProjectContext } from "../context/ProjectContext"; // Import the ProjectContext

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path,Permissions, component: Component, props }, index) => {

    if (Permissions && window.Roles) {
     // console.log("Permissions to this route : " +  Permissions);
     // console.log("current user roles : ", window.Roles);
    
      const hasPermission = window.Roles.some(role => Permissions.includes(role));
    
      if (hasPermission) {
       // console.log(`Access granted : Path ${path} Permissions: ${Permissions}`);
      } else {
        return null;
      }
    }
   let { projectId } = useContext(ProjectContext); // Access the projectId from the context
    if(projectId == null) {
      const projectInfo = JSON.parse(localStorage.getItem('projectId'));
      projectId = projectInfo;
    }
    if (children) {
      const renderedChildren = children.map(({ path, component: ChildComponent }, childIndex,Permissions ) => (
        <Route
          key={childIndex}
          path={path}
          exact
          render={props => (
            <Layout>
              <ChildComponent {...props} projectId={projectId} />
            </Layout>
          )}
        />
      ));
      
      return renderedChildren;
      
    }
    return (
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} projectId={projectId} />
          </Layout>
        )}
      />
    );
  });

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(LandingLayout, landingRoutes)}
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(AuthLayout, pageRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;

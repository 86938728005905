import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react"; 
import { FaFilter, FaSearch, FaAngleDown } from 'react-icons/fa';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Button } from "reactstrap";
import { RingLoader } from "react-spinners"; 
import { MoreVertical } from "react-feather";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { useHistory } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
const override = css`
  display: block;
  margin: 0 auto;
`;

const Products = () => {
  const [productsWithSku, setProductsWithSku] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [showDropDownSearch, setShowDropDownSearch] = useState(false);
  const dropdownRef = useRef(null);
  const [skus, setSkus] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState(new Set());
  const history = useHistory();
  const storedProject = JSON.parse(localStorage.getItem("erpProject"));
  const [assemblerAppProject, setAssemblerAppProject] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [kioskProject, setKioskProject] = useState(null);
  const [localDeviceProject, setLocalDeviceProject] = useState(null);

  useEffect(() => {
    loadOptions("");
    initializeLocalDeviceProject();
  }, []);

  const loadOptions = async (inputValue) => {
    setIsLoading(true);
    try {
      const apiUrl = "https://api2.fairs.as/24so/soap/projects";
      const response = await axios.get(apiUrl);

      const projectOptions = response.data.Project
        .filter(
          (item) =>
            item.Id.toString().includes(inputValue) ||
            item.Name.toLowerCase().includes(inputValue.toLowerCase()),
        )
        .map((item) => ({
          value: item.Id,
          label: item.Name,
          type: item.TypeName
        }));

      setProjectOptions(projectOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const initializeLocalDeviceProject = () => {
    const storedProject = localStorage.getItem("erpProject");
    if (storedProject) {
      const { value, label, type } = JSON.parse(storedProject);
      setLocalDeviceProject({ value, label, type });
    }
  };

  useEffect(() => {
    if (projectOptions.length > 0) {
      fetchKioskProject();
    }
  }, [projectOptions]);
  const fetchKioskProject = async () => {
    try {
      const apiUrl = "https://compassfairs.no/wp-json/fairsas/getKioskWP";
      const response = await axios.get(apiUrl, {
        auth: {
          username: 'admin',
          password: 'admin',
        },
      });
      
      const { erp_projectId } = response.data;

      if (erp_projectId) {
        const project = projectOptions.find(option => option.value === parseInt(erp_projectId));
        console.log(project); // Should log the project if found
        setKioskProject(project || null);
      }
    } catch (error) {
      console.error("Error fetching kiosk project:", error);
    }
  };

  const handleKioskProjectChange = async (selectedOption) => {
    setKioskProject(selectedOption);
    if (selectedOption) {
      try {
        const apiUrl = `https://compassfairs.no/wp-json/fairsas/setKioskWP`;
        const response = await axios.get(apiUrl, {
          params: { pid: selectedOption.value },
          auth: {
            username: 'admin',
            password: 'admin',
          },
        });

        const { project_id, updated } = response.data;

        if (updated) {
          toastr.success("Success", `Kiosk Project updated successfully to ${project_id}`);
        } else {
          toastr.error("Error", "Failed to update Kiosk Project.");
        }
      } catch (error) {
        console.error("Error updating kiosk project:", error);
        toastr.error("Error", "Failed to update Kiosk Project.");
      }
    }
  };


  useEffect(() => {
    setLoading(true);
    if(!kioskProject?.value) {
      return;
    } 
    fetch(`https://compassfairs.no/wp-json/fairsas/getProjectsProducts?pid=${kioskProject?.value}`)
      .then(response => response.json())
      .then(data => {
        if (data.products_with_sku) {
          setProductsWithSku(data.products_with_sku);
          const fetchedSkus = Object.values(data.products_with_sku);
          setSkus(fetchedSkus);
          setSelectedSkus(new Set(fetchedSkus));
        }
      })
      .catch(error => console.error('Error fetching products:', error))
      .finally(() => setLoading(false));
  }, [kioskProject?.value]);

  const handleDelete = (sku) => {
    const username = 'admin';
    const password = 'admin';

    fetch(`https://compassfairs.no/wp-json/fairsas/deleteProjectProducts?pid=${kioskProject?.value}&sku=${sku}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        if (data.deleted_product_id) {
          const newProductsWithSku = { ...productsWithSku };
          delete newProductsWithSku[data.deleted_product_id];
          setProductsWithSku(newProductsWithSku);
          setSkus(Object.values(newProductsWithSku));
          setSelectedSkus(new Set(Object.values(newProductsWithSku)));
        }
      })
      .catch(error => {
        console.error('Error deleting product:', error);
      });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const parseTitle = (title) => {
    const nbMatch = title.match(/\[:nb\](.*?)\[:/); // Match Norwegian title
    return nbMatch ? nbMatch[1] : title;
  };

  const filteredProducts = Object.keys(productsWithSku)
    .filter(id => productsWithSku[id]?.sku.includes(filter))
    .reduce((res, key) => (res[key] = productsWithSku[key], res), {});

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'title',
      text: 'Title',
      formatter: (cell, row) => parseTitle(row.title),
      sort: true,
    },
    {
      dataField: 'sku',
      text: 'SKU',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => (
        <Button color="danger" onClick={() => handleDelete(row.sku)}>
         Remove
        </Button>
      ),
    },
  ];

  const productsData = Object.keys(filteredProducts).map(id => ({
    id,
    sku: filteredProducts[id]?.sku,
    title: filteredProducts[id]?.title,
  }));

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Kiosk</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/expo-solutions">Expo Solutions</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/expo-solutions/products">Kiosk</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link active>{kioskProject?.label}</Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </Header>

      </Container>

      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Kiosk Project
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Select
                      options={projectOptions}
                      isClearable
                      onChange={handleKioskProjectChange}
                      value={kioskProject}
                      isSearchable
                      placeholder="Select or search for projects..."
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                  Results
                </CardTitle>
                <div style={{ display: 'inline-block', position: 'relative', marginLeft: '0', marginTop:'15px' }}>
                <div style={{ position: 'relative', marginBottom: '10px' }}>
                      <Input type="text" onChange={handleFilterChange} value={filter} placeholder="Search products" style={{ display: 'block', width: '100%', paddingLeft: '30px' }} />
                      <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
                </div>
              </div>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  data={productsData}
                  columns={columns}
                  search
                >
                  {
                    props => (
                      <BootstrapTable
                        {...props.baseProps}
                        striped
                        hover
                        condensed
                      />
                    )
                  }
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {loading && (
        <div className="loading-screen" style={{ marginTop: '125px' }}>
          <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
        </div>
      )}
    </>
  );
};

export default Products;

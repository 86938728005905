import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import { RingLoader } from "react-spinners";
import { MoreVertical } from "react-feather";
import { json2csv } from "json2csv"; // For CSV export
import { parse } from 'json2csv'; 
import * as XLSX from "xlsx"; // For Excel export
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Orders24SO = () => {
  // State declarations
  const [orderSlipStateTypes, setOrderSlipStateTypes] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedOrderSlipStateTypes, setSelectedOrderSlipStateTypes] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [dateFilter, setDateFilter] = useState("2023-01-01T00:00:00");
  const [errorMessage, setError] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [selectedOrders24SO, setSelectedOrders24SO] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [projectFilter, setProjectFilter] = useState("");

  // State for quote
  const [quote, setQuote] = useState("");
  const [quoteLoading, setQuoteLoading] = useState(true);

  // Fetch projects
  useEffect(() => {
    setIsLoadingProjects(true);
    axios
      .get('https://api2.fairs.as/24so/soap/projects')
      .then((response) => {
        const projects = response.data.Project.map((project) => ({
          value: project.Id,
          label: project.Name
        }));
        setProjectOptions(projects);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      })
      .finally(() => {
        setIsLoadingProjects(false);
      });
  }, []);

  // Fetch quote on component mount
  useEffect(() => {
    axios.get('https://api.forismatic.com/api/1.0/?method=getQuote&format=json&lang=en')
      .then(response => {
        setQuote(`${response.data.quoteText} — ${response.data.quoteAuthor}`);
      })
      .catch(error => {
        console.error('Error fetching quote:', error);
        setQuote("");
      })
      .finally(() => {
        setQuoteLoading(false);
      });
  }, []);

  // Define order slip state types
  useEffect(() => {
    setOrderSlipStateTypes([
      { value: "Invoiced", label: "Invoiced" },
      { value: "Confirmed", label: "Confirmed" },
      { value: "Web", label: "Web" },
      { value: "Production", label: "Production" },
      { value: "Return", label: "Credited" },
      { value: "ForInvoicing", label: "For Invoicing" },
      { value: "Offer", label: "Offer"},
      { value: "Pack", label: "Pack"},
    ]);
  }, []);

  // Define department options
  useEffect(() => {
    setIsLoadingDepartments(true);
    // Simulate fetch departments
    setDepartmentOptions([
      { value: 2, label: "Admin" },
      { value: 4, label: "Expo Solutions" },
      { value: 6, label: "Fairs" }
    ]);
    setIsLoadingDepartments(false);
  }, []);

  // Handle project selection
  const handleProjectSelect = (selectedOption) => {
    setSelectedProject(selectedOption);
  };

  // Handle order slip state type selection
  const handleOrderSlipStateTypeChange = (selectedOptions) => {
    setSelectedOrderSlipStateTypes(selectedOptions);
  };

  // Handle department selection
  const handleDepartmentChange = (selectedOptions) => {
    setSelectedDepartments(selectedOptions);
  };

  // Handle employeeId input change
  const handleEmployeeIdChange = (event) => {
    setEmployeeId(event.target.value);
  };

  const handleDateSearch = (event) => {
    setDateFilter(event.target.value);
  };

  const handleProductSearch = (event) => {
    setProductSearch(event.target.value);
  };
  const handleFilterChange = (event) => {
    setProjectFilter(event.target.value);
  };

  // Execute request
  const executeRequest = () => {
    if(!selectedProject?.value) {
      toastr.warning('[Warning]', 'No project selected. This may take longer than expected. Please select a project to optimise performance.');
    }
    setLoading(true);
    const requestData = {
      orderSlipStateType: selectedOrderSlipStateTypes.map(option => option.value),
      departmentIds: selectedDepartments.map(option => option.value),
      employeeId: employeeId ? parseInt(employeeId) : undefined,
      searchAfterProducts: productSearch ? productSearch : undefined,
      changedAfter: dateFilter ? dateFilter : undefined,
      startsWith: projectFilter ? projectFilter : undefined,
    };

    axios.post(`https://api2.fairs.as/24so/soap/salesreport?projectId=${selectedProject?.value}`, requestData)
      .then(response => {
        setSelectedOrders24SO(response.data.transformedInvoices);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Generate CSV file
  const handleExportCSV = () => {
    const csvData = selectedOrders24SO.map(order => ({
      'Ordredato': order.dateOrdered,
      'Fakturanr.': order.invoiceId,
      'Fakturadato': order.dateInvoiced,
      'Ordrenr.': order.orderId,
      'Status': order.orderStatus,
      'KundeID': order.CustomerId,
      'Kunde': order.customerName,
      'Orgnr.': order.orgNo,
      'Varenr.': order.productNo,
      'Linjebeskrivelse': order.productName,
      'Antall': order.quantity,
      'Pris': order.price,
      'Rabatt': order.discountRate,
      'Beløp': order.totalCost,
      'Prosjekt': order.projectId,
      'Prosjekt navn':  projectOptions.find(project => project.value === order.projectId)?.label || "Unknown Project",
      'Selgernr': order.employeeId,
      'Avd.nr': order.departmentId,
      'Selger': order.ourReference,
      'Ekstern ref.': order.yourReference,
      'Mobil': order.personalPhoneNumber,
      'Epost': order.personalworkEmail,
      'Faktura epost': order.invoiceEmailAddress,
      'Fakturatittel': order.invoiceTitle,
      'Fakturatekst': order.invoiceText,
      'Refnr.': order.referenceNumber,
      'Prodnr.': order.productionNumber
    }));

    // Convert to CSV and download
    const csv = parse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    let timestamp = Date.now();
    link.setAttribute("download", `${selectedProject?.value || 'all'}-${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Generate Excel file
  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(selectedOrders24SO.map(order => ({
      'Ordredato': order.dateOrdered,
      'Fakturanr.': order.invoiceId,
      'Fakturadato': order.dateInvoiced,
      'Ordrenr.': order.orderId,
      'Status': order.orderStatus,
      'Kunde': order.customerName,
      'KundeID': order.CustomerId,
      'Orgnr.': order.orgNo,
      'Varenr.': order.productNo,
      'Linjebeskrivelse': order.productName,
      'Antall': order.quantity,
      'Pris': order.price,
      'Rabatt': order.discountRate,
      'Beløp': order.totalCost,
      'Prosjekt': order.projectId,
      'Prosjekt navn':  projectOptions.find(project => project.value === order.projectId)?.label || "Unknown Project",
      'Selgernr': order.employeeId,
      'Avd.nr': order.departmentId,
      'Selger': order.ourReference,
      'Ekstern ref.': order.yourReference,
      'Mobil': order.personalPhoneNumber,
      'Epost': order.personalworkEmail,
      'Faktura epost': order.invoiceEmailAddress,
      'Fakturatittel': order.invoiceTitle,
      'Fakturatekst': order.invoiceText,
      'Refnr.': order.referenceNumber,
      'Prodnr.': order.productionNumber,

    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders24SO");
    let timestamp = Date.now();
    // Convert to Excel and download
    XLSX.writeFile(wb, `${selectedProject?.value || 'all'}-${timestamp}.xlsx`);
  };

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Orders report</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Reports</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Orders</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Filter Criteria
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                <Col md={8}>
                  <p>Project</p>
                  <Select
                    isClearable
                    options={projectOptions}
                    value={selectedProject}
                    onChange={handleProjectSelect}
                    isLoading={isLoadingProjects}
                    placeholder="Select Project"
                  />

                </Col>
                <Col md={4}>
                  <p>Project name (starts with)</p>
                  <Input
                    placeholder="Project filter"
                    value={projectFilter}
                    onChange={handleFilterChange}
                  />
                </Col>

                </Row>

                {/* Filter row with Order Slip State Types and Departments side by side */}
                <Row>
                  <Col md={6}>
                    <div className="my-3">
                    <p>Status</p>
                      <Select
                        isMulti
                        isClearable
                        options={orderSlipStateTypes}
                        value={selectedOrderSlipStateTypes}
                        onChange={handleOrderSlipStateTypeChange}
                        placeholder="Order Status"
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="my-3">
                    <p>Department</p>
                      <Select
                        isMulti
                        isClearable
                        options={departmentOptions}
                        value={selectedDepartments}
                        onChange={handleDepartmentChange}
                        isLoading={isLoadingDepartments}
                        placeholder="Departments"
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </Col>
                </Row>
                
                <Row>
                  <Col md={4}>
                    <div className="my-3">
                    <p>Product search</p>
                      <Input
                        placeholder="Product search (Starting with or exact)"
                        value={productSearch}
                        onChange={handleProductSearch}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="my-3">
                    <p>Date filter</p>
                      <Input
                        type="text"
                        placeholder="date"
                        value={dateFilter}
                        onChange={handleDateSearch}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="my-3">
                    <p>Employee search</p>
                      <Input
                        type="number"
                        placeholder="Enter Employee ID"
                        value={employeeId}
                        onChange={handleEmployeeIdChange}
                      />
                    </div>
                  </Col>

                </Row>



                {/* Button to execute request */}
                <button onClick={executeRequest} className="btn btn-primary">
                  Fetch data
                </button>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading ? (
        <>
        <div className="loading-screen">
          <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
          <br/>
        </div>
        <div>
        {quoteLoading ? (
            <p>Fetching quote...</p>
          ) : (

            <blockquote>
            <div className="loading-screen">
              <p>{quote}</p>
              </div>
            </blockquote>
           
          )}
        </div>
        </>
      ) : (
        selectedOrders24SO.length > 0 && (
          <Container fluid>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <div className="card-actions float-right">
                      <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle tag="a">
                          <MoreVertical />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={handleExportCSV}>Export CSV</DropdownItem>
                          <DropdownItem onClick={handleExportExcel}>Export Excel</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <CardTitle tag="h5" className="mb-0">
                      Results
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                  <div style={{ maxHeight: '980px', overflowY: 'auto' }}>
                      <Table id="printTable" className="mb-0 table-striped">
                        <thead>
                          <tr>
                            <th>Ordredato</th>
                            <th>Fakturanr.</th>
                            <th>Fakturadato</th>
                            <th>Ordrenr.</th>
                            <th>Status</th>
                            <th>KundeID</th>
                            <th>Kunde</th>
                            <th>Orgnr.</th>
                            <th>Varenr.</th>
                            <th>Linjebeskrivelse</th>
                            <th>Antall</th>
                            <th>Pris</th>
                            <th>Rabatt</th>
                            <th>Beløp</th>
                            <th>Prosjekt</th>
                            <th>Prosjekt navn</th>
                            <th>Avd.nr</th>
                            <th>Selger</th>
                            <th>Ekstern ref.</th>
                            <th>Epost</th>
                            <th>Mobil</th>
                            <th>Faktura epost</th>
                            <th>Fakturatittel</th>
                            <th>Fakturatekst</th>
                            <th>Refnr.</th>
                            <th>Prodnr.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedOrders24SO.filter(order => 
                            filter === "" || order.productNo.includes(filter)
                          ).map((order, index) => (
                            <tr key={index}>
                              <td>{order.dateOrdered}</td>
                              <td>{order.invoiceId}</td>
                              <td>{order.dateInvoiced}</td>
                              <td>{order.orderId}</td>
                              <td>{order.orderStatus}</td>
                              <td>{order.CustomerId}</td>
                              <td>{order.customerName}</td>
                              <td>{order.orgNo}</td>
                              <td>{order.productNo}</td>
                              <td>{order.productName}</td>
                              <td>{order.quantity}</td>
                              <td>{order.price}</td>
                              <td>{order.discountRate}</td>
                              <td>{order.totalCost}</td>
                              <td>{order.projectId}</td>
                              <td>
                                {
                                  projectOptions.find(project => project.value === order.projectId)?.label || "Unknown Project"
                                }
                              </td>
                              <td>
                                {
                                  departmentOptions.find(dept => dept.value === order.departmentId)?.label || "Unknown Department"
                                }
                              </td>
                              <td>{order.ourReference}</td>
                              <td>{order.yourReference}</td>
                              <td>{order.personalworkEmail}</td>
                              <td>{order.personalPhoneNumber}</td>
                              <td>{order.invoiceEmailAddress}</td>
                              <td>{order.invoiceTitle}</td>
                              <td>{order.invoiceText}</td>
                              <td>{order.referenceNumber}</td>
                              <td>{order.productionNumber}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )
      )}
    </>
  );
};

export default Orders24SO;

import React, { useState, useEffect, useRef } from 'react';
import { findBestMatch } from 'string-similarity';
import CameraComponent from './componsents/CameraComponent';
import AddTaskModal from './componsents/AddTaskModal';  
import PhotoGallery from './componsents/PhotoGallery';  
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { fetchCheckboxStates, fetchUserInfo, handleCheckboxClick, tableColumns, toggle } from './api/api'
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import { MoreVertical } from 'react-feather'; 
import { Link } from "react-router-dom";
import Header from "../../../src/components/Header";
import HeaderTitle from "../../../src/components/HeaderTitle";
import { RingLoader } from 'react-spinners';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FaFilter, FaAngleDown, FaSearch, FaAngleUp,  FaRegCheckSquare, FaExclamationTriangle, FaCamera  } from 'react-icons/fa';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamation,
  faGlobeAmericas,
  faInfo,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
const Rigginglist = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const history = useHistory();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cacheTimeStamp, setCacheTimeStamp] = useState(null);
  const [loadingCache, setLoadingCache] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [expandedCards, setExpandedCards] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStands, setSelectedStands] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [indexOfCheckState, setIndexOfCheckState] = useState([]);
  const [isStandsDropdownOpen, setIsStandsDropdownOpen] = useState(false);
  const [isStandsDropdownOpen1, setIsStandsDropdownOpen1] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
  const [projectName, setProjectName] = useState('');



  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');

  // Function to handle status filter changes
  const handleStatusFilterChange = (status) => {
    // Toggle selection if clicked on the same filter
    setSelectedStatusFilter(prevFilter => prevFilter === status ? '' : status);
  };

  const resetStatusFilter = () => {
    setSelectedStatusFilter('');
  };

  
  const modalRef = useRef(null);

  const [addTaskModalOpen, setAddTaskModalOpen] = useState([]);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  const toggleModalAtIndex = (index) => {
    setModalOpenIndex(modalOpenIndex === index ? null : index);
  };
  const storedProject = JSON.parse(localStorage.getItem("erpProject"));
  if (!storedProject) {
    toastr.error('[Error]', 'You need to set the local project before accessing this module.');
    history.push("/expo-solutions/config");
  }
  let labelValue = storedProject?.label;
  let projectId = storedProject?.value;
  let projectType = storedProject?.type;

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('assemblerAppProject'));
    

    /*
    if (!storedData || !storedData.label) {
      history.push('/assemblerapp/settings');
      alert('Select project');
    } else {
      labelValue = storedData.label;
      setProjectName(storedData.label);
    }*/

    //const projectName = storedData.label;
  }, [history]);
  

  const [searchStandAndCompany, setSearchStandAndCompany] = useState('');
  const [searchGlobal, setSearchGlobal] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState({
    stands: true,
    products: true,
    owner: true,
    statusComplete: false,
    productsSearch: true
  });
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropDownSearch, setShowDropDownSearch] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [handleClickCounter, setHandleClickCounter] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        if(projectType === 'Boligmesse') {
          response = await fetch(`https://api2.fairs.as/24so/soap/invoices2?projectId=${projectId}`);
        } else {
          response = await fetch(`https://api2.fairs.as/24so/soap/invoices?projectId=${projectId}`);
        }
     //  const response = await fetch('http://localhost:3002/24so/soap/invoices?projectId=24126');
        const invoicesData = await response.json();
        //console.log('Invoices data:', invoicesData); // Add this line to log the invoices data
        setInvoices(invoicesData.results);
        if(invoicesData.cachedSince) {
          setCacheTimeStamp(invoicesData.cachedSince);
        }
        
        const { selectedCheckboxStates, processedCheckboxStates } = await fetchCheckboxStates();
        setIndexOfCheckState(processedCheckboxStates);
        const userInfo = await fetchUserInfo();
        setUserInfo(userInfo);
        setCheckboxStates(selectedCheckboxStates);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);


  const regenerateCache = async () => {
    setLoadingCache(true); 
    let response;
    try {
      if (projectType === 'Boligmesse') {
        response = await fetch(`https://api2.fairs.as/24so/soap/invoices2?projectId=${projectId}&regenerateCache=true`);
      } else {
        response = await fetch(`https://api2.fairs.as/24so/soap/invoices?projectId=${projectId}&regenerateCache=true`);
      }
      const invoicesData = await response.json();
      setInvoices(invoicesData.results); // Update the invoices state with new data
      if(invoicesData.cachedSince) {
        setCacheTimeStamp(invoicesData.cachedSince);
      }
    } catch (error) {
      console.error('Error regenerating cache:', error);
    } finally {
      setLoadingCache(false);
    }
  };




  const handleClick = async (rowId, isSelected, Quantity) => {
    try {
      // Ensure Quantity is a string
      const quantityStr = Quantity != null ? String(Quantity) : '';
  
      const regex = /\((\d+)\)/;
      const match = quantityStr.match(regex);
      const valueInsideParentheses = match ? match[1] : null;
  
      const handleCheckbox = async () => {
        await handleCheckboxClick(rowId, isSelected, valueInsideParentheses || Quantity, setCheckboxStates, 'yasin');
        
        // Increment the counter and update the state
        setHandleClickCounter(prevCounter => {
          const newCounter = prevCounter + 1;
          
          // Only refresh every third time
          if (newCounter % 5 === 0) {
            // Fetch checkbox states in the background
            fetchCheckboxStates().then(({ selectedCheckboxStates, processedCheckboxStates }) => {
              // Defer state updates
              setTimeout(() => {
               // setIndexOfCheckState(processedCheckboxStates);
               // let tmpSearch = searchGlobal;
               // setSearchGlobal(' ');
               // setSearchGlobal(tmpSearch);
              }, 0);
            });
          }
          
          return newCounter;
        });
      };
  
      window.requestAnimationFrame(handleCheckbox);
  
    } catch (error) {
      // Handle error
      console.error('Error handling checkbox click:', error);
    }
  };


  const handleClick2 = async (rowId, isSelected, Quantity) => {
    try {
        await handleCheckboxClick(rowId, isSelected, Quantity, setCheckboxStates, 'yasin');
        console.log('I am clicked!!');
      
    } catch (error) {
      // Handle error
      console.error('Error handling checkbox click:', error);
    }
  };

  const toggleCard = (index) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };



  const isCardChecked = (invoice) => {
    const excludedCodes = ["GRA", "100", "env"];
    
    // Calculate the total number of relevant checkboxes
    const totalCheckboxes = invoice.InvoiceRows.flatMap(invoiceRowGroup =>
      invoiceRowGroup.InvoiceRow.filter(invoiceRow =>
        invoiceRow.Type !== "Text" && !excludedCodes.some(code =>
          invoiceRow.ProductNo && invoiceRow.ProductNo.substring(0, 3).toLowerCase() === code.toLowerCase()
        )
      )
    ).length;
  
    // Calculate the number of relevant checkboxes that are checked
    const checkedCheckboxes = invoice.InvoiceRows.flatMap(invoiceRowGroup =>
      invoiceRowGroup.InvoiceRow.filter(invoiceRow =>
        checkboxStates.some(state => state.id === invoiceRow.RowId)
      )
    ).length;
  
    // Return true if all relevant checkboxes are checked
    return checkedCheckboxes >= totalCheckboxes;
  };
  
  
  
  const countTasksAndCompleted = (invoice) => {
    const excludedCodes = ["GRA", "100", "env"];
  
    // Calculate the total number of tasks
    const totalTasks = invoice.InvoiceRows.flatMap(invoiceRowGroup =>
      invoiceRowGroup.InvoiceRow.filter(invoiceRow =>
        invoiceRow.Type !== "Text" && !excludedCodes.some(code =>
          invoiceRow.ProductNo && invoiceRow.ProductNo.substring(0, 3).toLowerCase() === code.toLowerCase()
        )
      )
    ).length;
  
    if (!Array.isArray(checkboxStates)) {
      console.error('checkboxStates is not an array:', checkboxStates);
      return { totalTasks, completedTasks: 0 };
    }
    const completedTasks1 = invoice.InvoiceRows.flatMap(invoiceRowGroup =>
      invoiceRowGroup.InvoiceRow.filter(invoiceRow =>
        checkboxStates.some(checkboxState => parseInt(checkboxState.id, 10) === invoiceRow.RowId)
      )
    ).length;


    const completedTasks2 = invoice.InvoiceRows.flatMap(invoiceRowGroup =>
      invoiceRowGroup.InvoiceRow.filter(invoiceRow => {
        const hasAssemblerQaName = indexOfCheckState[invoiceRow.RowId]?.assemblerQaName !== undefined;
        const isProjectLeader = indexOfCheckState[invoiceRow.RowId]?.projectLeader === 1;
        const isCheckedStatus = indexOfCheckState[invoiceRow.RowId]?.checked;
        return isCheckedStatus ;
      })
    ).length;
  
  
    const completedTasks = isCardChecked(invoice) ? totalTasks : completedTasks1;
  
  
    return { totalTasks, completedTasks, completedTasks2 };
  };
  
  const handleSearchChange2 = (event) => {
    setSearchStandAndCompany(event.target.value);
  };
  
  


  const handleFilterTypeClick = (type) => {
    setActiveFilters(prevFilters => ({
      ...prevFilters,
      [type]: !prevFilters[type]
    }));
    setIsDropdownOpen(false);
  };

  const handleFilterClick = (filter) => {
    if (selectedStands.includes(filter)) {
      setSelectedStands(selectedStands.filter(stand => stand !== filter));
    } else {
      setSelectedStands([...selectedStands, filter]);
    }
  };


  const handleFilterClickOwners = (filter) => {
    if (selectedOwners.includes(filter)) {
      setSelectedOwners(selectedOwners.filter(employee => employee !== filter));
    } else {
      setSelectedOwners([...selectedOwners, filter]);
    }
  };


  const handleProductSelect = (productCode) => {
    setSelectedProducts((prevSelectedProducts) => {
      if (prevSelectedProducts.includes(productCode)) {
        return prevSelectedProducts.filter((code) => code !== productCode);
      } else {
        return [...prevSelectedProducts, productCode];
      }
    });
  };

  const filterBySelectedProducts = (invoice) => {
    if (selectedProducts.length === 0) {
      return true; // No products selected, return all invoices
    } else {
      // Flatten the nested array structure to access each InvoiceRow
      const invoiceRows = invoice.InvoiceRows.flatMap(invoiceRowGroup => invoiceRowGroup.InvoiceRow);
      
      // Check if any selected product codes match the first three characters of ProductNo in any InvoiceRow
      const result = invoiceRows.some(invoiceRow =>
        invoiceRow.ProductNo && selectedProducts.includes(invoiceRow.ProductNo.substring(0, 3))
      );
      
      return result;
    }
  };


  const filterBySelectedProductsSearch = (invoice, searchTerm) => {
    // Check if invoice is valid and has InvoiceRows
    if (!invoice || !invoice.InvoiceRows || !Array.isArray(invoice.InvoiceRows)) {
      return false; // Handle invalid or empty invoice data or InvoiceRows
    }
  
    // Flatten InvoiceRows structure to get an array of InvoiceRow objects
    const invoiceRows = invoice.InvoiceRows.flatMap(invoiceRowGroup => invoiceRowGroup.InvoiceRow);
  
    // Check if searchTerm is defined and not empty
    if (!searchTerm || typeof searchTerm !== 'string' || searchTerm.trim() === '') {
      return true; // Return true to indicate no filtering should be applied
    }
  
    // Check if any ProductNo exactly matches searchTerm (case insensitive)
    const foundInvoiceRow = invoiceRows.find(invoiceRow =>
      invoiceRow && invoiceRow.ProductNo && invoiceRow.ProductNo.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  
    return foundInvoiceRow !== undefined; // Return true if found, false otherwise
  };
  

  const extractProductCodes = (invoices) => {
    const productCodes = [];
  
    if (!invoices || !Array.isArray(invoices)) {
      return productCodes;
    }
  
    invoices.forEach(invoice => {
      if (invoice && Array.isArray(invoice.InvoiceRows)) {
        invoice.InvoiceRows.forEach(rowGroup => {
          if (rowGroup && Array.isArray(rowGroup.InvoiceRow)) {
            rowGroup.InvoiceRow.forEach(invoiceRow => {
              if (invoiceRow && invoiceRow.ProductNo) {
                const code = invoiceRow.ProductNo.substring(0, 3);
                if (
                  !productCodes.includes(code)// Check if code hasn't been added already
                   // Check if code matches search term
                ) {
                  productCodes.push(code);
                }
              }
            });
          }
        });
      }
    });
  
    return productCodes;
  };
  
  const productCodeFetch = extractProductCodes(invoices);

  useEffect(() => {
    const productCodes = extractProductCodes(invoices);
    const excludedCodes = ["GRA", "100", "env"];
    const filteredCodes = productCodes.filter(code => !excludedCodes.includes(code));
    setSelectedProducts(filteredCodes);
  }, [invoices, searchTerm])

  

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(showDropdown);
        setIsStandsDropdownOpen(isStandsDropdownOpen);
        setShowDropDownSearch(showDropDownSearch);
        setShowDropdown1(showDropdown1);
        setIsStandsDropdownOpen1(isStandsDropdownOpen1)
      }


    };

    document.addEventListener('mousedown', handleClickOutside, { passive: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filterBySearchTerm1 = (invoice, searchTerm) => {
    // Flatten the nested array structure to access each InvoiceRow
    const invoiceRows = invoice.InvoiceRows.flatMap(invoiceRowGroup => invoiceRowGroup.InvoiceRow);
  
    // Check if the search term matches any ProductNo
    return !searchTerm || invoiceRows.some(invoiceRow =>
      invoiceRow.ProductNo && invoiceRow.ProductNo.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  

  const getUniqueFirstLetters = (invoices) => {
    const allFirstLetters = invoices.flatMap(invoice => 
      invoice.ReferenceNumber
        .filter(ref => typeof ref === 'string' && ref.length > 0)
        .map(ref => ref.charAt(0))
    );
  
    return [...new Set(allFirstLetters)];
  };
  

  const getUniqueOwners = (invoices) => {
    const allReferences = invoices.flatMap(invoice => invoice.OurReference);
    return [...new Set(allReferences)];
  };
  const uniqueFirstLetters = invoices ? getUniqueFirstLetters(invoices) : [];
  const Owners = invoices ? getUniqueOwners(invoices) : [];
  const filteredInvoices = invoices ? invoices.filter(invoice => {
    // Check if invoice is defined and has necessary properties
    if (!invoice || !Array.isArray(invoice.ReferenceNumber) || typeof invoice.OurReference !== 'string' || typeof searchGlobal !== 'string') {
      return false;
    }
  
    // Ensure CustomerName and ReferenceNumber are arrays
    const referenceNumbers = Array.isArray(invoice.ReferenceNumber) ? invoice.ReferenceNumber : [];
    const customerNames = Array.isArray(invoice.CustomerName) ? invoice.CustomerName : [];
  
    const matchesSearchTerm = referenceNumbers.some(ref => ref && typeof ref === 'string' && ref.toLowerCase().includes(searchStandAndCompany.toLowerCase())) || 
      customerNames.some(name => name && typeof name === 'string' && name.toLowerCase().includes(searchStandAndCompany.toLowerCase()));
  
    const filteredByStands = selectedStands.length === 0 || referenceNumbers.some(ref =>
      ref && typeof ref === 'string' && selectedStands.includes(ref.charAt(0))
    );
  
    const filteredByOwners = selectedOwners.length === 0 || selectedOwners.includes(invoice.OurReference);
  
    const filteredBySearchTerm = filterBySearchTerm1(invoice, searchGlobal);
    const isChecked = isCardChecked(invoice);
    const statusFilterMatch = (selectedStatusFilter === 'Complete' && isChecked) ||
    (selectedStatusFilter === 'Not Complete' && !isChecked) ||
    !selectedStatusFilter; // Show all if no status filter selected


    return (selectedStands.length === 0 || filteredByStands) && 
           (selectedOwners.length === 0 || filteredByOwners) && 
           (searchGlobal.length === 0 || filteredBySearchTerm) && 
           (searchStandAndCompany.length === 0 || matchesSearchTerm) && 
          (filterBySelectedProductsSearch(invoice,searchTerm)) && filterBySelectedProducts(invoice) && statusFilterMatch;
  }) : [];
  const toggleExpandAll = () => {
    if (Object.keys(expandedCards).length === filteredInvoices.length) {
      // All cards are already expanded, collapse all
      setExpandedCards({});
    } else {
      // Expand all cards
      const newExpandedCards = {};
      filteredInvoices.forEach((_, index) => {
        newExpandedCards[index] = true;
      });
      setExpandedCards(newExpandedCards);
    }
  };

  if (loading) {
    return (
      <div className="loading-screen2">
        <RingLoader size={130} color={"#123abc"} loading={loading} />
        <p>Processing, Please Wait...</p>
      </div>
    );
  }


  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgColor: '#dcf5dc',
    selected: checkboxStates.map(state => state.id),
    onSelect: (row, isSelected) => {
      // Update the indexOfCheckState to ensure it's synchronized
      indexOfCheckState[row.RowId] = {
        ...indexOfCheckState[row.RowId],
        checked: isSelected,
        quantity: row.Quantity
      };
      // Use requestAnimationFrame to avoid blocking the main thread
      window.requestAnimationFrame(() => {
        handleClick(row.RowId, isSelected, row.Quantity);
      });
    }
  };
  

  const printAllCards = () => {
    const newExpandedCards = {};
    filteredInvoices.forEach((_, index) => {
      newExpandedCards[index] = true;
    });
    setExpandedCards(newExpandedCards);
    const printContents = document.getElementById("printTable").outerHTML;
    
    const printWindow = window.open("", "_blank");
  
    // Write the HTML for the print window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="/css/modern.css" rel="stylesheet" type="text/css">');
    printWindow.document.write('<link href="/css/print.css" rel="stylesheet" type="text/css" media="print">');
    printWindow.document.write('<style>.card { page-break-inside: avoid; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');

    printWindow.document.close(); // Close the document after writing
  };

  return (
    <Container fluid >
    <Header style={{marginBottom: '2rem !important'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <HeaderTitle>Checklist</HeaderTitle>
        <Button color="primary" onClick={regenerateCache} disabled={loadingCache}>
          {loadingCache ? <Spinner size="sm" /> : 'Reload data'}
        </Button>
      </div>
      
      <div className="header-content" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard/assemblerDashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Checklist</BreadcrumbItem>
          <BreadcrumbItem active>{labelValue ? labelValue : 'Project not set!'}</BreadcrumbItem>
        </Breadcrumb>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {cacheTimeStamp && (
            <p style={{ color: "white", marginTop: '5px' }}>
              Updated: {new Date(cacheTimeStamp).toLocaleString('no-NO', { 
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short'
              })}
            </p>
          )}
        </div>
      </div>
    </Header>
      <Row>
        <Col>
          <Card>
            <CardBody style={{padding:'1.75rem'}}>
            <div ref={dropdownRef}  style={{ display: 'inline-block', cursor: 'pointer' }}>
                  {/* FaFilter icon with UncontrolledDropdown */}
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle tag="a" className="filter-icon" style={{ display: 'inline-block', cursor: 'pointer' }}>
                      <FaFilter />
                    </DropdownToggle>
                    <DropdownMenu left="true">
                      <DropdownItem onClick={() => handleFilterTypeClick('stands')}>
                        Filter stands
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFilterTypeClick('products')}>
                        Filter categories
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFilterTypeClick('productsSearch')}>
                        Search products
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFilterTypeClick('owner')}>
                        Filter owner
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFilterTypeClick('statusComplete')}>
                        Filter status
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                {activeFilters.products && (
                  <div className=""style={{ display: 'inline-block', position: 'relative', marginLeft: '10px' }}>
                    <span className="badge2" onClick={() => setShowDropdown(!showDropdown)} style={{ backgroundColor: 'lightpurple', color: '#f9fafc', borderRadius: '20px', padding: '5px 15px', cursor: 'pointer', backgroundColor: selectedProducts.length > 0 ? 'lightgrey' : 'lightpurple', }}>
                      Categories <FaAngleDown />
                    </span>
                    {showDropdown && (
                  <div ref={dropdownRef} className="dropdown-menu3" style={{ position: 'absolute', top: '100%', left: '0', zIndex: '1000', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px',  }}>
                    {/*<div style={{ position: 'relative', marginBottom: '10px' }}>
                      <input type="text" onChange={handleSearchGlobal} value={searchGlobal} placeholder="Search category" style={{ display: 'block', width: '100%', paddingLeft: '30px' }} />
                      <FaSearch style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
                    </div>*/}
                    <div style={{ marginBottom: '10px', color: 'gray', marginLeft: '15px' }}>
                      Results
                    </div>
                    {productCodeFetch.map((productCode) => (
                          <div key={productCode} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <label
                              htmlFor={productCode}
                              style={{
                                flex: '1',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                transition: 'box-shadow 0.3s',
                                width: '100%'
                              }}
                            >
                              <input
                                type="checkbox"
                                id={productCode}
                                name={productCode}
                                value={productCode}
                                checked={selectedProducts.includes(productCode)}
                                style={{ marginRight: '10px', width: '20px', height: '20px' }}
                                onClick={() => handleProductSelect(productCode)}
                              />
                              {productCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {activeFilters.stands && (
                  <div  style={{ display: 'inline-block', position: 'relative', marginLeft: '10px' }}>
                    <span className="badge2" onClick={() => setIsStandsDropdownOpen(!isStandsDropdownOpen)} style={{ backgroundColor: (selectedStands.length === 1 ? 'lightgrey' : ''), color: '#f9fafc', borderRadius: '20px', padding: '5px 15px', cursor: 'pointer' }}>
                      Stands <FaAngleDown />
                    </span>
                    {isStandsDropdownOpen && (
                      <div ref={dropdownRef} className="dropdown-menu2">
                        {uniqueFirstLetters.map(letter => (
                          <div key={letter} onClick={() => handleFilterClick(letter)} style={{ padding: '5px 10px', cursor: 'pointer' }}>
                            {letter} {selectedStands.includes(letter) && <span className="badge2" style={{ backgroundColor: 'lightpurple', color: 'black', borderRadius: '24px', padding: '0px 5px', marginLeft: '5px', float:'right' }}>✓</span>}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {activeFilters.productsSearch && (
                  <div  style={{ display: 'inline-block', position: 'relative', marginLeft: isMobile ? '21px' : '10px', marginTop: isMobile ? '15px' : '0px'  }}>
                    <span className="badge2" onClick={() => setShowDropDownSearch(!showDropDownSearch)} style={{ backgroundColor: 'lightpurple', color: '#f9fafc', borderRadius: '20px', padding: '5px 15px', cursor: 'pointer' }}>
                      Products <FaAngleDown />
                    </span>
                    {showDropDownSearch && (
                  <div ref={dropdownRef} className="dropdown-menu2" style={{ position: 'absolute', top: '100%', left: '0', zIndex: '1000', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px', width:'210px' }}>
                    <div style={{ position: 'relative', marginBottom: '10px' }}>
                      <input type="text" onChange={handleSearchChange} value={searchTerm} placeholder="Search products" style={{ display: 'block', width: '100%', paddingLeft: '30px',  }} />
                      <FaSearch style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
                    </div>
                      </div>
                    )}
                  </div>
                )}

                {activeFilters.owner && (
                  <div  style={{ display: 'inline-block', position: 'relative', marginLeft: isMobile ? '21px' : '10px', marginTop: isMobile ? '15px' : '0px'  }}>
                    <span className="badge2" onClick={() => setShowDropdown1(!showDropdown1)} style={{ backgroundColor: 'lightpurple', color: '#f9fafc', borderRadius: '20px', padding: '5px 15px', cursor: 'pointer', backgroundColor: (selectedOwners.length === 1 ? 'lightgrey' : '') }}>
                      Owners <FaAngleDown />
                    </span>
                    {showDropdown1 && (
                  <div ref={dropdownRef} className="dropdown-menu3" style={{ position: 'absolute', top: '100%', left: '0', zIndex: '1000', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px' }}>
                    <div style={{ marginBottom: '10px', color: 'gray', marginLeft: '15px' }}>
                      Results
                    </div>
                    {Owners.map((owner) => (
                          <div key={owner} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <label
                              htmlFor={owner}
                              style={{
                                flex: '1',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                transition: 'box-shadow 0.3s',
                                width: '100%'
                              }}
                            >
                              <input
                                type="checkbox"
                                id={owner}
                                name={owner}
                                value={owner}
                                style={{ marginRight: '10px', width: '20px', height: '20px' }}
                                checked={selectedOwners.includes(owner)}
                                onChange={() => handleFilterClickOwners(owner)}
                              />
                              {owner}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {activeFilters.statusComplete && (
                  <div ref={dropdownRef} style={{ display: 'inline-block', position: 'relative', marginLeft: isMobile ? '21px' : '17px', marginTop: isMobile ? '15px' : '0px'  }}>
                    <span className="badge2" onClick={() => setIsStandsDropdownOpen1(!isStandsDropdownOpen1)} style={{ backgroundColor: (selectedStatusFilter  ? 'lightgrey' : ''), color: '#f9fafc', borderRadius: '20px', padding: '5px 15px', cursor: 'pointer' }}>
                      Status <FaAngleDown />
                    </span>
                    {isStandsDropdownOpen1 && (
                      <div ref={dropdownRef} className="dropdown-menu2">
                      {['Complete', 'Not Complete'].map((status) => (
                          <div key={status} onClick={() => handleStatusFilterChange(status)} style={{ width:"100%", display: 'inline-block', cursor: 'pointer' }}>
                            {status} {selectedStatusFilter === status && <span className="badge2" >✓</span>}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              <div className="card-actions float-right">
                  <span className="cursor-pointer mr-2">
                </span>{" "}
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle tag="a">
                    <MoreVertical />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={printAllCards}>Print all</DropdownItem>
                    <DropdownItem onClick={toggleExpandAll}>{Object.keys(expandedCards).length === filteredInvoices.length
          ? 'Hide all cards'
          : 'Expand all cards'}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
                <div style={{ position: 'relative', marginTop:'25px' }}>
                  <input 
                    type="text" 
                    onChange={handleSearchChange2} 
                    value={searchStandAndCompany} 
                    placeholder="Search stand/company" 
                    style={{ display: 'block', width: '98%', paddingLeft: '30px' }} 
                  />
                  <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />

                </div>
                

      
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="printTable" id="printTable">
      {filteredInvoices.map((invoice, index) => {
        const isChecked = isCardChecked(invoice);
        const {totalTasks, completedTasks, completedTasks2} = countTasksAndCompleted(invoice);
        return (
          <Card key={index} style={{ marginBottom: '20px', padding: '15px' }}>
            <CardHeader>
            <div className="card-actions"  style={{ float:'right', cursor:'pointer', marginTop:'10px' }}>
          <div className="card-actions float-right">
                  <span className="cursor-pointer mr-2">
                </span>{" "}
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle tag="a">
                    <MoreVertical />
                  </DropdownToggle>
                  <DropdownMenu>
                  <DropdownItem  onClick={() => toggleModalAtIndex(index)}>
                    Add Task
                  </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <AddTaskModal
                    isOpen={modalOpenIndex === index}
                    toggle={() => toggleModalAtIndex(index)} // Pass the toggle function to control modal open/close
                    inputValue1={invoice.CustomerName}
                    inputValue2={invoice.ReferenceNumber}
                    projectPath={labelValue}
                  />


              </div>
              </div>
              <div className="hideForMobile" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CardTitle style={{ fontSize: "19px", fontWeight: 800 }}>{invoice.CustomerName ? invoice.CustomerName : 'Not set'}</CardTitle>
                  <span style={{ margin: '0 10px', paddingBottom: '10px', fontSize:'22px' }}>•</span>
                  <CardTitle style={{ fontSize: '19px', fontWeight:100 }}>{invoice.YourReference.join(', ') ? invoice.YourReference[0] : 'Not set'}</CardTitle>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  <span style={{cursor:'pointer'}}>{<PhotoGallery folder={`${labelValue}/${invoice.CustomerName} - (${invoice.ReferenceNumber})/`}/>} <CameraComponent folder={`${labelValue}/${invoice.CustomerName} - (${invoice.ReferenceNumber})/`} /> <CardTitle style={{ borderRadius: '20px', border: '0.5px solid rgb(211 206 206)', display: 'inline-block', padding: '5px 20px', backgroundColor: 'rgb(21 61 119)', color: '#d6d8e5' }}> 
                  <strong>{invoice.ReferenceNumber ? invoice.ReferenceNumber : 'Not set'}</strong>
                  </CardTitle> </span>

                </div>
              </div>
              {isMobile && (
              <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CardTitle style={{ fontSize: "17px", fontWeight: 800, whiteSpace: 'nowrap', overflow: 'scroll' }}>
                  {invoice.CustomerName ? invoice.CustomerName : 'Not set'}
                </CardTitle>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CardTitle style={{ fontSize: "13px", fontWeight: 500, whiteSpace: 'nowrap', overflow: 'scroll' }}>
                  {invoice.YourReference.join(', ') ? invoice.YourReference[0] : 'Not set'}
                </CardTitle>
              </div>


              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CardTitle style={{ 
                  width: "80px", 
                  fontSize: "14px", 
                  fontWeight: 800, 
                  borderRadius: '20px', 
                  border: '0.5px solid rgb(211, 206, 206)', 
                  display: 'inline-block', 
                  padding: '5px 20px', 
                  backgroundColor: 'rgb(21, 61, 119)', 
                  color: '#d6d8e5',
                  marginRight: '10px',
                  whiteSpace: 'nowrap', overflow: 'scroll' 
                }}>
                  <strong>{invoice.ReferenceNumber ? invoice.ReferenceNumber : 'Not set'}</strong>
                </CardTitle>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom:'20px' }}>
                  <PhotoGallery folder={`${labelValue}/${invoice.CustomerName} - (${invoice.ReferenceNumber})/`} />
                  <CameraComponent folder={`${labelValue}/${invoice.CustomerName} - (${invoice.ReferenceNumber})/`} />
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                    src={(() => {
                      const matches = findBestMatch(invoice.OurReference, Object.keys(userInfo));
                      const bestMatch = matches.bestMatch;
                      const bestMatchIndex = matches.bestMatchIndex;
                      const similarity = bestMatch.rating;

                      const threshold = 0.5; 

                      if (similarity >= threshold) {
                        const matchedUserName = bestMatch.target;
                        return userInfo[matchedUserName]?.picture || 'https://placehold.co/600x400?text=C+F';
                      }
                      return 'https://placehold.co/600x400?text=C+F'; 
                    })()}
                    alt="User Picture"
                    width="48"
                    height="48"
                    className="rounded-circle mr-2"
                  />

                  <CardTitle style={{ color: '#7f7f7f', margin: 'unset', fontSize:'14px' }}>
                    <strong>{invoice.OurReference ? invoice.OurReference : 'Not set'}&nbsp;</strong>
                  </CardTitle>
              </div>

              <div>
                  {isChecked ? (
                    <span >
                      {!toggle ? (
                        <span style={{marginRight:'10px'}}>
                          Delivered ({completedTasks2} of {totalTasks}) - Verified ({completedTasks} of {totalTasks})
                        </span>
                      ) : (
                        <span style={{marginRight:'10px'}}>
                          Delivered {completedTasks2} of {totalTasks}
                        </span>
                      )}
                    <Button color="success" className="mr-1 mb-1">
                      <FontAwesomeIcon icon={faCheck} />
                    </Button> 
                    </span>
                  ) : (
                    <span >
                      {!toggle ? (
                          <span style={{marginRight:'10px', fontSize:'13px'}} >
                            Delivered ({completedTasks2} of {totalTasks}) - Verified ({completedTasks} of {totalTasks})
                          </span>
                        ) : (
                          <span style={{marginRight:'10px', fontSize:'13px'}}>
                            Delivered {completedTasks2} of {totalTasks}
                          </span>
                        )}
                    <Button color="warning" className="mr-1 mb-1">
                      <FontAwesomeIcon icon={faExclamation} />
                    </Button>
                    </span>
                  )}
                </div>
            
            </div>
              )}

              <div className="hideForMobile" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={(() => {
                        const matches = findBestMatch(invoice.OurReference, Object.keys(userInfo));
                        const bestMatch = matches.bestMatch;
                        const bestMatchIndex = matches.bestMatchIndex;
                        const similarity = bestMatch.rating;

                        const threshold = 0.5; 

                        if (similarity >= threshold) {
                          const matchedUserName = bestMatch.target;
                          return userInfo[matchedUserName]?.picture || 'https://placehold.co/600x400?text=C+F';
                        }
                        return 'https://placehold.co/600x400?text=C+F'; 
                      })()}
                      alt="User Picture"
                      width="48"
                      height="48"
                      className="rounded-circle mr-2"
                    />
                    <CardTitle style={{ color: '#7f7f7f', margin: 'unset' }}>
                      <strong>{invoice.OurReference ? invoice.OurReference : 'Not set'}&nbsp;</strong>
                    </CardTitle>
                  <div>
                  
                  </div>
                  
                  </div>
                  
                  <div>
                    {isChecked ? (
                      <span>
                        {!toggle ? (
                          <span style={{marginRight:'10px'}}>
                            Delivered ({completedTasks2} of {totalTasks}) - Verified ({completedTasks} of {totalTasks})
                          </span>
                        ) : (
                          <span style={{marginRight:'10px'}}>
                            Delivered {completedTasks2} of {totalTasks}
                          </span>
                        )}
                      <Button color="success" className="mr-1 mb-1">
                        <FontAwesomeIcon icon={faCheck} />
                      </Button> 
                      </span>
                    ) : (
                      <span >
                        {!toggle ? (
                            <span style={{marginRight:'10px'}}>
                              Delivered ({completedTasks2} of {totalTasks}) - Verified ({completedTasks} of {totalTasks})
                            </span>
                          ) : (
                            <span style={{marginRight:'10px'}}>
                              Delivered {completedTasks2} of {totalTasks}
                            </span>
                          )}
                      <Button color="warning" className="mr-1 mb-1">
                        <FontAwesomeIcon icon={faExclamation} />
                      </Button>
                      </span>
                    )}
                  </div>
                </div>
              {!expandedCards[index] && (
                  <div style={{ flex: 1, textAlign: 'center' }}>
                    <div style={{fontSize: 'xx-large', height:'32px'}}> <FaAngleDown               onClick={() => toggleCard(index)}
              style={{ backgroundColor: isChecked ? '' : '', cursor: 'pointer' }} /> </div>
                    
                  </div>
                )}
            </CardHeader>
            <CardBody  className="card-bodyClass" style={{ display: expandedCards[index] ? 'block' : 'none', backgroundColor: expandedCards[index] ? '' : '#e6ffe6' }}>
              <ToolkitProvider
                keyField="RowId"
                data={invoice.InvoiceRows.flatMap(invoiceRowGroup =>
                  invoiceRowGroup.InvoiceRow.filter(invoiceRow => invoiceRow.Type !== "Text")
                ).filter(invoiceRow => {
                  // Filter based on selected products
                  const productFilter = selectedProducts.length === 0 || selectedProducts.some(product => 
                    invoiceRow.ProductNo && invoiceRow.ProductNo.toLowerCase().startsWith(product.toLowerCase())
                  );
                // Filter based on search term
                const searchTermFilter = searchTerm === '' || invoiceRow.ProductNo.toLowerCase().startsWith(searchTerm.toLowerCase());

                // Return true if the row matches either product filter or search term filter
                return productFilter && searchTermFilter;
                }).map(row => {
                  const projectLeader = indexOfCheckState[row.RowId]?.projectLeader || '';
                  const projectLeaderValue = indexOfCheckState[row.RowId]?.editedBy || '';
                  const assemblerQaName = indexOfCheckState[row.RowId]?.assemblerQaName || '';
                  const quantity = indexOfCheckState[row.RowId]?.quantity || '';
                  const id = indexOfCheckState[row.RowId]?.id;
                  const checkedStatus = indexOfCheckState[row.RowId]?.checked;
                  if (projectLeader && checkedStatus) {
                    row.Status1 = `Verified by ${projectLeaderValue}`;
                } else if (assemblerQaName && checkedStatus) {
                    row.Status1 = `Delivered by ${assemblerQaName}`;
                } else {
                    row.Status1 = '';
                }
                  let actualVal = row.Quantity;
                  const quantityRegex = /^(\d+)\s\(\d+\)$/;


                  if (!quantityRegex.test(parseInt(actualVal, 10)) && quantity && quantity <= actualVal ) {
                    // If actualVal is not equal to quantity
                    if (parseInt(actualVal,10) !== parseInt(quantity, 10)) {
                        // Update row.Quantity to include both values with parentheses
                        row.Quantity = (actualVal-quantity) + " (" + actualVal + ")";
                        
                        // Update indexOfCheckState and checkboxStates
                        if (indexOfCheckState[row.RowId] && indexOfCheckState[row.RowId].checked) {
                            indexOfCheckState[row.RowId].checked = false;
                            const checkboxState = checkboxStates.find(state => state.id === row.RowId);
                            if (checkboxState) {
                                handleClick2(row.RowId, false, parseInt(quantity, 10));
                            }
                        }
                    } else {
                        // If actualVal is equal to quantity, just set row.Quantity to actualVal
                        row.Quantity = actualVal;
                    }
                }
                  return row;
                })}
                columns={tableColumns}
                exportCSV
              >
                {props => (
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    bordered={false}
                    selectRow={selectRow}
                  />
                )}
              </ToolkitProvider>
              <div style={{ textAlign: 'center', marginTop: '10px', fontSize:'xx-large' }}>
                {expandedCards[index] && <FaAngleUp onClick={() => toggleCard(index)} />}
              </div>
            </CardBody>
          </Card>
        );
      })}

    </div>

    </Container>
  );
};

export default Rigginglist;

const userData = JSON.parse(localStorage.getItem('userData'));
export let toggle = false; // Default toggle value if user data or role not found
if (userData && typeof userData === 'object') {
    const hasAssemblerQARole = userData.userRoles && userData.userRoles.some(role => ['Chief Assembler', 'Assembler'].includes(role));
    toggle = hasAssemblerQARole; // Set toggle based on user role
}

export const fetchCheckboxStates = async () => {
  try {

      const storedProject = JSON.parse(localStorage.getItem("erpProject"));
      let projectId = storedProject?.value;
      const response = await fetch(`https://api2.fairs.as/ta-orders/checkstates/${projectId}`);
      const checkboxStatesData = await response.json();


      const processedCheckboxStates = checkboxStatesData.data.reduce((acc, state) => {
        // Add each state to the object with the ID as the key
        acc[parseInt(state.id, 10)] = {
          quantity: state.quantity,
          editedBy: state.editedBy,
          assemblerQaName: state.assemblerQaName,
          checked: state.checked,
          projectLeader: state.projectLeader,
          id: parseInt(state.id,10)
        };
        return acc;
      }, {});


      const selectedCheckboxStates = checkboxStatesData.data
          .filter(state => {
              if (toggle) {
                  // If user has AssemblerQA role, filter by checked and assemblerQaName
                  return state.checked === true && (state.assemblerQaName !== undefined && state.assemblerQaName !== '') || state.projectLeader === 1;
              } else {
                  // If user does not have AssemblerQA role, filter by checked and (assemblerQaName or projectLeader)
                  return state.checked === true && state.projectLeader === 1;
              }
          })
          .map(state => ({
              id: parseInt(state.id, 10),
              quantity: state.quantity,
              editedBy: state.editedBy,
              assemblerQaName: state?.assemblerQaName,
              projectLeader: state?.projectLeader,
              checked: state.checked,
          }));


          return {
            selectedCheckboxStates,
            processedCheckboxStates
          };
  } catch (error) {
      console.error('Error fetching checkbox states:', error);
      throw error; // Rethrow the error to handle it in the component
  }
};


  export const fetchUserInfo = async () => {
    try {
      const response = await fetch('https://api2.fairs.as/auth/users');
      const userInformation = await response.json();
      
      // Creating an object to store user information with name as index
      const indexedUserInfo = {};
      userInformation.forEach(user => {
        const index = user.name.trim();
        indexedUserInfo[index] = {
          email: user.email,
          number: user.number,
          picture: user.picture ? user.picture : 'https://placehold.co/600x400?text=C+F'
        };
      });
      return indexedUserInfo;
    } catch (error) {
        console.error('Error fetching checkbox states:', error);
        throw error; // Rethrow the error to handle it in the component
    }
  };

  export const handleCheckboxClick = async (rowId, isSelected, Quantity, setCheckboxStates) => {
    try {
      const storedProject = JSON.parse(localStorage.getItem("erpProject"));
      let projectId = storedProject?.value;
        const body = {
            checked: isSelected,
            editedBy: userData.name,
            Quantity: Quantity,
            projectLeader: isSelected ? 1 : 0,
          };
          if (toggle) {
            body.assemblerQaName = userData.name;
          }
        const response = await fetch(`https://api2.fairs.as/ta-orders/checkstates/${projectId}/${rowId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

  
      if (!response.ok) {
        throw new Error('Failed to update checkbox state');
      }
  
      // Update checkbox states in the component using the provided setCheckboxStates function
      setCheckboxStates(prevState =>
        isSelected ? [...prevState, { id: rowId, quantity: Quantity, editedBy: userData.name }] : prevState.filter(state => state.id !== rowId)
      );
    } catch (error) {
      console.error('Error updating checkbox state:', error);
      alert('Failed to update checkbox state. Please try again.');
    }
  };


  
  export const tableColumns = [
      {
          dataField: 'ProductNo',
          text: 'SKU',
          sort: false,
          headerStyle: { width: '30%' }
      },
      {
          dataField: 'Name',
          text: 'Name',
          headerStyle: { width: '55%' },
          sort: true
      },
      {
          dataField: 'Quantity',
          text: 'QTY',
          sort: false,
          headerStyle: { width: '15%', textAlign: 'center' }, 
          style: { textAlign: 'center',  fontWeight: 800}, 
      },
  ];
  
  if (!toggle) {
    tableColumns.push({
        dataField: 'Status1',
        text: 'Status',
        sort: false,
        headerStyle: { width: '25%' },
    });
}

  
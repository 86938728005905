import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Table
} from "reactstrap";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import WebTicketsTable from "./Components/webtickets";
import ExhibitorsTable from "./Components/exhibitorticket";
import ProjectSelector from "./Components/projectSelector";
const DashboardDefaultPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [data, setData] = useState({
    webTickets: [],
    exhibitors: [],
    expoBox: []
  });

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    fetchData(project.value); 
  };

  const fetchData = async (selectedProjectId) => {
    try {
      const response = await fetch(
        `https://boligmesse.no/nb/wp-json/RJSDashboard/v1/data?ticket=${selectedProjectId}`
      );
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
     if(selectedProject) fetchData()
  }, []); 

  const { webTickets, exhibitors } = data;
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Exhibitor Report</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Exhibitor Report System</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 pt-2">
                  Select Project
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ProjectSelector
                  apiUrl={"https://boligmesse.no"}
                  onProjectSelect={handleProjectSelect}
                />
                <div className="my-3"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      <Row>
        <div style={{ margin: "20px 0" }} />
        <Col>
        {exhibitors.length > 0 && (
          <div>
            <ExhibitorsTable exhibitors={exhibitors} totalUsedWEB={data.totalUsedEx} totalOrderedWEB={data.totalOrderedEx} />
          </div>)}
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardDefaultPage;

import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
  const [projectId, setProjectId] = useState(null);

  const updateProjectId = newProjectId => {
    setProjectId(newProjectId);
  };

  return (
    <ProjectContext.Provider value={{ projectId, updateProjectId }}>
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectProvider };

import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
const ExhibitorsTable  = ({ exhibitors, totalUsedWEB, totalOrderedWEB }) => (
    <div>
      <Card>
        <CardHeader>
          <CardTitle tag="h3" className="mb-0">
            Utstiller
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Navn</th>
                <th>Bestilte</th>
                <th>Brukte</th>
              </tr>
            </thead>
            <tbody>
              {exhibitors.map((exhibitor, index) => (
                <tr key={index}>
                  <td>{exhibitor.name}</td>
                  <td>{exhibitor.total}</td>
                  <td>{exhibitor.used}</td>
                </tr>
              ))}
                <tr>
                  <td><b style={{fontSize: 'large'}}>{'Totals:'}</b></td>
                  <td>{totalOrderedWEB}</td>
                  <td>{totalUsedWEB}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
);

export default ExhibitorsTable;